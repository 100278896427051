import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
// import axios from '../utils/axios';;
import axios from "../utils/axios";
import { API_URL } from "../utils/Constant";
// import { EditOutlined } from "@mui/icons-material";
import { DocumentEditor } from "@onlyoffice/document-editor-react";
import ButtonComp from "../components/button/ButtonComp";
import SuccessButton from "../components/button/SuccessButton";
import { formatIndianDate } from "../utils/Constant";
import { signupUser } from "../api/api";
import CustomSearch from "../components/search/CustomSearch";
import { getAllISOCodes } from "iso-country-currency";
import FinancialYearFolder from "../components/bulkDownload";

import {
  LoadingOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Descriptions,
  Button,
  Input,
  Space,
  Modal,
  message,
  Form,
  Popconfirm,
  Tabs,
  Table,
  Col,
  Upload,
  Tooltip,
  AutoComplete,
  Spin,
} from "antd";
import Select from "react-select";
import { InboxOutlined } from "@ant-design/icons";
import { handlePdfDownload } from "../utils/utils";
import { FaDownload } from "react-icons/fa6";

// import SuccessMessage from "../../components/messages/SuccessMessages";
import {
  settingsUpdate,
  settingsUpdateError,
} from "../components/messages/ResponseMessages";
import { limitWords } from "../utils/utils";
import { capitalizeAndRemoveUnderscores } from "../utils/utils";

const { TextArea } = Input;

const { TabPane } = Tabs;
const { Dragger } = Upload;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

function SettingsPage() {
  const company_id = localStorage.getItem("company_id");
  const user_role = localStorage.getItem("user_role");
  const token = localStorage.getItem("access_token");
  const [activeTab, setActiveTab] = useState("1");
  const [company, setCompany] = useState();
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [editMode, setEditMode] = useState(false);
  const [isCompanyCreateModalOpen, setIsCompanyCreatedModalOpen] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [editedCompany, setEditedCompany] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isProductModal, setIsProductModal] = useState(false);
  const [isAssignModal, setIsAssignModal] = useState(false);

  const [companyID, setCompanyID] = useState(true);
  const [products, setProducts] = useState([]);
  const [executedContracts, setExecutedContracts] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);

  const [isExtraFieldModalOpen, setIsExtraFieldModalOpen] = useState(false);
  const [finalPage, setFinalPage] = useState(6);
  const [users, setUsers] = useState([]);
  const [assignee, setAssignee] = useState([]);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [companyId, setCompanyId] = useState([]);

  const [open, setOpen] = useState(false);
  const [activeOpen, setActiveOpen] = useState(false);
  const [userFormModal, setUserFormModal] = useState(false);
  const [department, setDepartment] = useState();
  const [allDepartments, setAllDepartments] = useState();
  const [allCurrency, setAllCurrency] = useState([]);

  const [userId, setUserId] = useState([]);
  const modalText = "Are you sure you want to Activate this user?";
  const [business_head_id, setBusinessHeadId] = useState();
  const [businessHead, setBusinessHead] = useState();
  const [role, setRole] = useState();
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    params: {
      search: "",
    },
  };

  useEffect(() => {
    if (user_role === "superadmin") {
      setFinalPage(7);
    } else if (user_role === "admin") {
      setFinalPage(5);
    } else {
      setFinalPage(4);
    }
  }, [user_role]);
  const [form] = Form.useForm();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const codes = await getAllISOCodes();
        const transformedData = codes.map((code) => ({
          value: `${code.countryName} (${code.symbol})`,
          label: `${code.countryName} (${code.symbol})`,
        }));
        setAllCurrency(transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  // console.log(company?.address?.country)

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(API_URL + "/user/get/", config);
      setUsers(response.data); // Update the state with the fetched user data
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchUsers();
  }, []);
  const fetchAssignee = async (role) => {
    try {
      const response = await axios.get(API_URL + "/user/get/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          role: role,
        },
      });
      setAssignee(response.data); // Update the state with the fetched user data
    } catch (error) {
      console.error(error);
    }
  };
  const onClick = (record) => {
    setIsAssignModal(true);
    form.resetFields();
    const transformedText = record.role.replace(/\s/g, "").toLowerCase();
    const role =
      transformedText.charAt(0).toUpperCase() + transformedText.slice(1);
    fetchAssignee(role);
  };

  const fetchExecutedContracts = async (month = null, year = null) => {
    try {
      setLoading(true);
      const response = await axios.get(API_URL + "/contract/get/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          status: "EXECUTED",
          month: month,
          year: year,
        },
      });
      setExecutedContracts(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchCompanies = async (search) => {
    try {
      setLoading(true);
      const response = await axios.get(API_URL + "/company/get/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          search: search,
        },
      });
      setAllCompanies(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const userColumnsAdmin = [
    {
      title: "S. No.",
      dataIndex: "index",
      key: "index",
    },

    {
      title: "User's Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "User's Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (department) => (department ? department.name : ""),
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      key: "employee_id",
    },
    {
      title: "Profile",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Activation Date",
      dataIndex: "activation_date",
      key: "activation_date",
      render: (date) => formatIndianDate(date),
    },
    {
      title: "Change Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <>
          <Space>
            {record.is_active ? (
              <div
                onClick={() => showUserModal(record.id)}
                className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-red-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              >
                Deactivate
              </div>
            ) : (
              <div
                onClick={() => showActiveModal(record.id)}
                className="inline-block rounded-full border-2 border-success px-6 pb-[6px] pt-2 bg-green-500 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-success-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-success-600 focus:border-success-600 focus:text-black focus:outline-none focus:ring-0 active:border-success-700 active:text-black-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
              >
                Activate
              </div>
            )}
          </Space>
        </>
      ),
    },
    {
      title: "Assign Agreement",
      dataIndex: "age",
      key: "age",
      render: (text, record) => (
        <>
          <SuccessButton text="Click" onClick={() => onClick(record)} />
        </>
      ),
    },
    {
      title: "View details",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link
          to={{
            pathname: `/admin/user/${record.id}`,
          }}
        >
          <Button
            type="submit"
            className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-blue-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            View details
          </Button>
        </Link>
      ),
    },
  ];
  const userColumnsElse = [
    {
      title: "S. No.",
      dataIndex: "index",
      key: "index",
    },

    {
      title: "User's Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "User's Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (department) => (department ? department.name : ""),
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      key: "employee_id",
    },
    {
      title: "Profile",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Activation Date",
      dataIndex: "activation_date",
      key: "activation_date",
      render: (date) => formatIndianDate(date),
    },
    {
      title: "View details",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link
          to={{
            pathname: `/admin/user/${record.id}`,
          }}
        >
          <Button
            type="submit"
            className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-blue-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            View details
          </Button>
        </Link>
      ),
    },
  ];

  const companiesColumns = [
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Admin Contact No.",
      dataIndex: "admin_contact_number",
      key: "admin_contact_number",
    },
    {
      title: "Admin Name",
      dataIndex: "name_of_admin",
      key: "name_of_admin",
    },
    {
      title: "Admin Email",
      dataIndex: "admin_email",
      key: "admin_email",
    },
    {
      title: "Valid From",
      dataIndex: "valid_from",
      key: "valid_from",
      render: (date) => formatIndianDate(date),
    },
    {
      title: "Valid To",
      dataIndex: "valid_to",
      key: "valid_to",
      render: (date) => formatIndianDate(date),
    },
    {
      title: "Change Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => (
        <>
          {record.is_active ? (
            <div
              onClick={() => showADeactivateeModal(record.id)}
              type="submit"
              className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-red-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            >
              Deactivate
            </div>
          ) : (
            <div
              onClick={() => showCompanyActiveModal(record.id)}
              className="inline-block rounded-full border-2 border-success px-6 pb-[6px] pt-2 bg-green-500 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-success-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-success-600 focus:border-success-600 focus:text-black focus:outline-none focus:ring-0 active:border-success-700 active:text-black-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
            >
              Activate
            </div>
          )}
        </>
      ),
    },
    {
      title: "View details",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => (
        <Link
          to={{
            pathname: `/admin/company/${record.id}`,
          }}
        >
          <Button
            type="submit"
            className="inline-block rounded-full border-2 border-red px-6 pb-[6px] pt-2 bg-blue-400 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            View details
          </Button>
        </Link>
      ),
    },
  ];

  const handleOk = () => {
    form.submit();
  };

  const handleActivate = (userId) => {
    axios
      .put(
        `${API_URL}/user/${userId}/active/`,
        { active: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setActiveOpen(false);
          // Redirect to login page
          // window.location.href = "/admin/inactive_users";
          message.success("User activated successfully");
          fetchUsers();
        } else {
          setActiveOpen(false);
          // throw new Error("Error updating user status.");
        }
      })
      .catch((error) => {
        setActiveOpen(false);
        message.error(error.response.data.error);
        setOpen(false);
      });
  };

  const handleCompanyActivate = (id) => {
    axios
      .put(
        `${API_URL}/company/${id}/active/`,
        { active: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setActiveOpen(false);
          // Redirect to login page
          // window.location.href = "/admin/inactive_users";
          message.success("Company activated successfully");
          fetchUsers();
        } else {
          setActiveOpen(false);
          // throw new Error("Error updating user status.");
        }
      })
      .catch((error) => {
        setActiveOpen(false);
        message.error(error.response.data.error);
        setOpen(false);
      });
  };

  const departments = company && company.department_list;

  const fetchProduct = async () => {
    try {
      const response = await axios.get(
        API_URL + "/company/get_product/",
        config
      );
      setProducts(response.data.data); // Update the state with the fetched user data
    } catch (error) {}
  };

  const handleDepartment = (values) => {
    values.department_list.push(...departments);
    axios
      .patch(
        API_URL + `/company/${company_id && company_id}/update/`,
        values,
        config
      )
      .then((response) => {
        // Handle successful response
        setEditMode(false);
        settingsUpdate(response.data.message);
        fetchCompanys();
        setIsModalOpen(false);
        form.resetFields();
      })
      .catch((error) => {
        message.error("Some error occured while updating. Please try again.");
        // settingsUpdateError(
        //   "Some error occured while updating. Please try again."
        // );
      });

    setEditMode(false);
  };

  const handleProduct = (values) => {
    const product_list = [
      ...products.map((item) => item.product_name),
      ...(Array.isArray(values.product_list) ? values.product_list : []),
    ];
    axios
      .put(
        API_URL + "/company/add_product/",
        { product_list: product_list },
        config
      )
      .then((response) => {
        // Handle successful response
        setEditMode(false);
        settingsUpdate(response.data.message);
        fetchProduct();
        setIsProductModal(false);
        form.resetFields();
      })
      .catch((error) => {
        settingsUpdateError(
          "Some error occured while updating. Please try again."
        );
      });
  };

  const deleteDepartment = (values) => {
    let data = {
      department_list: departments.filter((item) => item !== values),
    };

    axios
      .patch(
        API_URL + `/company/${company_id && company_id}/update/`,
        data,
        config
      )
      .then((response) => {
        // Handle successful response
        message.success("Department successfully deleted.");
        fetchCompanys();
      })
      .catch((error) => {
        message.error("An error occured.");
        console.error("Error updating user details:", error);
      });
  };

  const deleteProduct = (values) => {
    let data = products
      .filter((item) => item.product_name !== values)
      .map((item) => item.product_name);

    axios
      .put(API_URL + "/company/add_product/", { product_list: data }, config)
      .then((response) => {
        // Handle successful response
        message.success("Product successfully deleted.");
        fetchProduct();
      })
      .catch((error) => {
        message.error("An error occured.");
        console.error("Error updating user details:", error);
      });
  };

  const handleFinishExtraField = (values) => {
    const transformedObject = values?.extra_fields?.reduce((acc, obj) => {
      const { field, value } = obj;
      acc[field] = value;
      return acc;
    }, {});
    const newKey = { ...transformedObject, ...company.extra_field };
    axios
      .patch(
        API_URL + `/company/${company_id && company_id}/update/`,
        { extra_field: newKey },
        config
      )
      .then((response) => {
        // Handle successful response
        setEditMode(false);
        settingsUpdate(response.data.message);
        fetchCompanys();
        setIsExtraFieldModalOpen(false);
        form.resetFields();
      })
      .catch((error) => {
        settingsUpdateError(
          "Some error occured while updating. Please try again."
        );
        console.error("Error updating user details:", error);
      });

    setEditMode(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showActiveModal = (userId) => {
    setActiveOpen(true);
    setUserId(userId);
    setCompanyId(companyId);
  };
  const showCompanyActiveModal = (companyId) => {
    setActiveOpen(true);
    setCompanyId(companyId);
  };
  const showADeactivateeModal = (companyId) => {
    setDeactivateModal(true);
    setCompanyId(companyId);
  };
  const showUserFormModal = (userId) => {
    setUserFormModal(true);
  };
  const showCompanyCreateModal = () => {
    setIsCompanyCreatedModalOpen(true);
  };
  const showProductModal = () => {
    setIsProductModal(true);
  };
  const showExtraFieldModal = () => {
    setIsExtraFieldModalOpen(true);
  };
  const handleOkExtraField = () => {
    setIsExtraFieldModalOpen(false);
  };
  const handleOkCompanyCreate = async () => {
    await form.validateFields();
    const values = await form.getFieldsValue();
    // setIsCompanyCreatedModalOpen(false);
    console.log(values);
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
    setIsExtraFieldModalOpen(false);
    setIsProductModal(false);
    setIsCompanyCreatedModalOpen(false);
    setIsAssignModal(false);
    setDeactivateModal(false);
    form.resetFields();
  };
  const handleCancelExtraFieldModal = () => {
    setIsExtraFieldModalOpen(false);
  };
  const handleEdit = () => {
    if (
      (user_role && user_role.toLowerCase() === "admin") ||
      user_role.toLowerCase() === "superadmin"
    ) {
      setEditMode(true);
    }
  };
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(API_URL + "/country/get/");
        setCountries(response.data); // Update the state with the fetched user data
      } catch (error) {}
    };
    fetchCountry();
  }, []);

  const fetchState = async (value) => {
    try {
      const response = await axios.get(API_URL + "/state/get/", {
        params: {
          country_id: value.value,
        },
      });

      setStates(response.data); // Update the state with the fetched user data
    } catch (error) {}
  };

  const handleCancel = () => {
    setEditMode(false);
    setEditedCompany({ ...company });
    setOpen(false);
    setActiveOpen(false);
    setUserFormModal(false);
    setIsAssignModal(false);
    setIsCompanyCreatedModalOpen(false);
    setDeactivateModal(false);
    form.resetFields();
  };
  const showUserModal = (userId) => {
    setUserId(userId);
    setOpen(true);
  };
  useEffect(() => {
    if (!company || !company.address || !company.address.country) return;
    fetchState({ value: company.address.country.id });
  }, [company]);

  const handleCountryChange = (value) => {
    form.setFieldsValue({ "address.country": value });
    setCompanyID(value.value);
    setEditedCompany((prevAddress) => ({
      ...prevAddress,
      address: {
        ...prevAddress.address,
        country: value.value,
      },
    }));
    fetchState(value);
  };

  const handleAdress1Change = (e) => {
    const { name, value } = e.target;
    setEditedCompany((prevAddress) => ({
      ...prevAddress,
      address: {
        ...prevAddress.address,
        [name]: value,
      },
    }));
  };

  const handleAddress2Change = (e) => {
    const { name, value } = e.target;
    setEditedCompany((prevAddress) => ({
      ...prevAddress,
      address: {
        ...prevAddress.address,
        [name]: value,
      },
    }));
  };
  const handleCityChange = (e) => {
    const { name, value } = e.target;
    setEditedCompany((prevAddress) => ({
      ...prevAddress,
      address: {
        ...prevAddress.address,
        [name]: value,
      },
    }));
  };

  const handlePinChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

    if (/^[0-9]{0,6}$/.test(numericValue)) {
      // Validate numeric input with a maximum length of 6
      setEditedCompany((prevAddress) => ({
        ...prevAddress,
        address: {
          ...prevAddress.address,
          [name]: numericValue,
        },
      }));
    } else {
      message.error(
        "Invalid input. Please enter a numeric value with a maximum length of 6."
      );
    }
  };
  const handleStateChange = (value) => {
    console.log(value, "KJSHDjkahsdjkhasd");
    setEditedCompany((prevAddress) => ({
      ...prevAddress,
      address: {
        ...prevAddress.address,
        state: value.value,
      },
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCompany((prevCompany) => ({
      ...prevCompany,
      [name]: value,
    }));
  };

  const fetchCompanys = async () => {
    try {
      const response = await axios.get(
        API_URL + `/company/${company_id && company_id}/get/`,
        config
      );
      setCompany(response.data);
      setEditedCompany(response.data);
      localStorage.setItem("currency", response.data.currency);
      const {
        address: { state, country, ...addressWithoutCountryState },
        ...otherData
      } = response.data;
      const editedCompany = {
        address: {
          ...addressWithoutCountryState,
          country: country && country.id,
          state: state && state.id,
        },
        ...otherData,
      };
      setEditedCompany(editedCompany);
      // window.location.reload(); // Update the state with the fetched company data
    } catch (error) {}
  };
  useEffect(() => {
    // Fetch company data from the API
    fetchCompanys();
  }, [editMode]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    const userNavigate = localStorage.getItem("gyrwmnqijpkl");
    if (userNavigate === "xvzndrpkloqy") {
      setActiveTab("5");
      localStorage.removeItem("gyrwmnqijpkl");
    } else if (userNavigate === "xvzndrpkloqo9") {
      setActiveTab("7");
      localStorage.removeItem("gyrwmnqijpkl");
    }
  }, []);

  const handleNext = () => {
    form.validateFields().then(() => {
      const nextTab = (parseInt(activeTab, 10) % finalPage) + 1;
      setActiveTab(nextTab.toString());
    });
  };

  const handlePrevious = () => {
    const previousTab =
      ((parseInt(activeTab, 10) - 2 + finalPage) % finalPage) + 1;
    setActiveTab(previousTab.toString());
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      axios
        .put(
          `${API_URL}/user/${userId}/active/`,
          { active: false, reason: values.reason },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // Redirect to login page
            // window.location.href = "/admin/settings";
            setOpen(false);
            fetchUsers();
            message.success("User deactivated successfully.");
            form.resetFields();
          } else {
            setOpen(false);

            throw new Error("Error updating user status.");
          }
        })
        .catch((error) => {
          // Display error message
          setOpen(false);

          console.error(error.message);
        });
    });
  };

  const handleCompanyStatus = (action) => {
    form.validateFields().then((values) => {
      axios
        .put(
          `${API_URL}/company/${companyId}/active/`,
          {
            active: action === "deactivate" ? false : true,
            ...(action === "deactivate" && { reason: values.reason }),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            // Redirect to login page
            // window.location.href = "/admin/settings";
            setDeactivateModal(false);
            fetchCompanies();
            message.success("Company deactivated successfully.");
            form.resetFields();
          } else {
            setDeactivateModal(false);
            message.error("An error occured. Please try again.");
          }
        })
        .catch((error) => {
          // Display error message
          setDeactivateModal(false);

          message.error(error.response.data.error);
        });
    });
  };
  const onFinish = async () => {
    await form.validateFields();
    const values = await form.getFieldsValue();
    setEditedCompany({ ...editedCompany, name: values.name });
    try {
      axios
        .patch(
          API_URL + `/company/${company_id && company_id}/update/`,
          editedCompany,
          config
        )
        .then((response) => {
          // Handle successful response
          setEditMode(false);
          fetchCompanys();
          settingsUpdate(response.data.message);
        })
        .catch((error) => {
          setEditMode(false);
          message.error(error.response.data.error);
        });
    } catch (errorInfo) {
      console.log(errorInfo);
      // setActiveTab("1");
      const errorFields = errorInfo.errorFields[0].name;

      // // Find the corresponding tab index for the first error field
      message.error(`Form validation failed, please check all the fields.`);
    }
  };
  const handleCompanyCreate = async () => {
    await form.validateFields();
    const values = await form.getFieldsValue();
  };
  const userWithIds = users
    ? users.map((item, index) => ({
        ...item,
        index: index + 1,
        // created_at: new Date(item.created_at).toLocaleDateString(),
      }))
    : [];

  const generateConfig = (customParams = {}) => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ...customParams, // Merge customParams into the params object
      },
    };
  };
  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        const config = generateConfig({ is_active: "False", page_size: 100 });
        const response = await axios.get(API_URL + "/department/get/", config);
        setAllDepartments(response.data); // Update the state with the fetched user data
      } catch (error) {}
    };
    fetchDepartment();
  }, [userFormModal]);
  const fetchHeadName = async (props) => {
    try {
      const config = generateConfig(props);
      const response = await axios.get(API_URL + "/user/get/", config);
      setBusinessHead(response.data);
    } catch (error) {}
  };
  const handleBusinessHeadId = (value) => {
    setBusinessHeadId(value);
  };
  const handleRole = (value) => {
    setRole(value);
  };
  useEffect(() => {
    if (role === "Businessteam") {
      fetchHeadName({ role: "Businesshead" });
    } else if (role === "Legalteam") {
      fetchHeadName({ role: "Legalhead" });
    }
  }, [role]);

  const handleCurrencyChange = (value) => {
    // form.setFieldsValue({ "address.country": value });
    // setCompanyID(value.value);
    setEditedCompany((prevAddress) => ({
      ...prevAddress,
      currency: value.value,
    }));
    // fetchState(value);
  };

  const onFinishUser = async () => {
    await form.validateFields();
    const values = await form.getFieldsValue();
    if (values.business_head_id) {
      values.business_head_id = values.business_head_id.value;
    }
    values.department = values.department.value;
    values.role = values.role.value;

    try {
      setLoading(true);
      const { res } = await signupUser(
        {
          department: values.department,
          email: values.email,
          employee_id: values.employee_id,
          role: values.role,
          first_name: values.first_name,
          ...(values.business_head_id && {
            business_head_id: values.business_head_id,
          }),
        },
        token
      );
      if (res.status === 201) {
        message.success("User created successfully.");
        form.resetFields();
        fetchUsers();
        setUserFormModal(false);
        setLoading(false);
        setRole(null);
        // return (window.location.href = "/auth/login/");
      } else {
        message.error(res);
        form.resetFields();
        setUserFormModal(false);
        setLoading(false);
        setRole(null);
      }
    } catch (err) {
      message.error(err.error);
      form.resetFields();
      setUserFormModal(false);
      setLoading(false);
      setRole(null);
    }
  };

  const onFinishAssignee = () => {
    form.validateFields();
    const values = form.getFieldsValue();
    axios
      .put(
        `${API_URL}/contract/assign_agreement_to_user/`,
        { user_id: values.user_id.value },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          message.success("Agreements assigned successfully.");
          form.resetFields();
          setIsAssignModal(false);
        } else {
          setIsAssignModal(false);
          throw new Error("Error updating user status.");
        }
      })
      .catch((error) => {
        setOpen(false);
        setIsAssignModal(false);
        console.error(error.message);
      });
  };

  const handleSearch = (value) => {
    config.params.search = value;
    fetchUsers();
  };

  const handleCompanySearch = (value) => {
    config.params.search = value;
    fetchCompanies(value);
  };

  const props = {
    name: "file",
    multiple: true,
    action: `${API_URL}/contract/bulk-upload/`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    accept: ".xlsx",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    onError(error) {
      console.error("Upload error:", error);
      message.error("An error occurred during upload. Please try again.");
    },
  };

  useEffect(() => {
    fetchProduct();
    fetchExecutedContracts();
    if (user_role === "superadmin") {
      fetchCompanies();
    }
  }, []);

  const columns = [
    {
      title: "User Name",
      dataIndex: "user_name",
      render: (userName, record) => <span>{userName}</span>,
    },
    {
      title: "Agreement Type",
      dataIndex: "agreement_type",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Partner Name",
      dataIndex: "name_of_client",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
        // {/* <span>
        //   {text && text.charAt(0).toUpperCase() + text.slice(1)}
        // </span> */}
      ),
    },
    {
      title: "Nature",
      dataIndex: "nature",
      render: (text, record) => (
        <span>{text && text.charAt(0).toUpperCase() + text.slice(1)}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <span>{capitalizeAndRemoveUnderscores(text)}</span>
      ),
    },
    {
      title: "Remarks(if any)",
      dataIndex: "remarks",
      ellipsis: true, // Enable ellipsis for the column
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Download",
      dataIndex: "template_file",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handlePdfDownload(record.agreement_file)}
          >
            <FaDownload />
          </Button>
        </Space>
      ),
    },
  ];

  const getCurrentFinancialYear = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based
    const currentYear = currentDate.getFullYear();
    let startYear, endYear;

    if (currentMonth >= 4) {
      // Financial year starts in April
      startYear = currentYear;
      endYear = currentYear + 1;
    } else {
      startYear = currentYear - 1;
      endYear = currentYear;
    }

    return `${startYear}-${endYear}`;
  };

  const currentFinancialYear = getCurrentFinancialYear();

  const [financialYears] = useState([
    { year: currentFinancialYear, isOpen: false },
    { year: "2023-2024", isOpen: false },
    { year: "2022-2023", isOpen: false },
    // Add more fiscal years as needed
  ]);

  return (
    <>
      {company ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              encType="multipart/form-data"
            >
              <Tabs
                activeKey={activeTab}
                onChange={handleTabChange}
                type="card"
                style={{ flex: 1 }}
              >
                <TabPane tab="Company Details" key="1">
                  <div className=" text-black pb-3 px-2 flex items-center justify-between">
                    <span>
                      <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                        Company Details
                      </h1>
                    </span>

                    {((user_role && user_role.toLowerCase() === "admin") ||
                      user_role.toLowerCase() === "superadmin") &&
                    !editMode ? (
                      <Button
                        type=""
                        style={{
                          border: "1px solid #4E36A3",
                          color: "#4E36A3",
                          height: "40px", // Adjust height for desired size
                          width: "120px",
                        }}
                        onClick={handleEdit}
                      >
                        Edit
                      </Button>
                    ) : null}
                  </div>

                  <Descriptions
                    title=""
                    layout="vertical"
                    className=""
                    bordered
                  >
                    <Descriptions.Item label="Company Name" span={2}>
                      {editMode && editedCompany ? (
                        <Input
                          name="name"
                          value={editedCompany.name}
                          onChange={handleInputChange}
                        />
                      ) : (
                        company.name
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Base Currency" span={2}>
                      {editMode && editedCompany ? (
                        <Select
                          options={allCurrency}
                          onChange={handleCurrencyChange}
                          defaultValue={allCurrency.find(
                            (currency) => currency.value === company.currency
                          )}
                        />
                      ) : (
                        company.currency
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Active Status">
                      {!company.is_active ? (
                        <Badge color="red" status="" text="Inactive" />
                      ) : (
                        <Badge status="success" text="Running" />
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Admin's Name">
                      {company.name_of_admin}
                    </Descriptions.Item>
                    <Descriptions.Item label="Admin's Contact">
                      {editMode && editedCompany ? (
                        <Input
                          name="admin_contact_number"
                          value={editedCompany.admin_contact_number}
                          onChange={handleInputChange}
                        />
                      ) : (
                        company.admin_contact_number
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Admin's Email">
                      {editMode && editedCompany ? (
                        <Input
                          name="admin_email"
                          value={editedCompany.admin_email}
                          onChange={handleInputChange}
                        />
                      ) : (
                        company.admin_email
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Company License No.">
                      <Form.Item
                        name="name"
                        // rules={[
                        //   {
                        //     validator: validateInput,
                        //   },
                        // ]}
                        style={{ marginBottom: 0 }} // Set marginBottom to 0
                        // hasFeedback
                      >
                        {editMode && editedCompany ? (
                          <Input
                            onChange={handleInputChange}
                            value={editedCompany.cin}
                            name="cin"
                            defaultValue={editedCompany.cin}
                          />
                        ) : (
                          <span>{company.cin}</span>
                        )}
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label="Company's Contact">
                      {editMode && editedCompany ? (
                        <Input
                          name="contact_no"
                          value={editedCompany.contact_no}
                          onChange={handleInputChange}
                        />
                      ) : (
                        company.contact_no
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="TAX License No.">
                      <Form.Item
                        name="gst_number"
                        // rules={[
                        //   {
                        //     validator: validateGSTNumber,
                        //   },
                        // ]}
                        style={{ marginBottom: 0 }} // Set marginBottom to 0
                        // hasFeedback
                      >
                        {editMode && editedCompany ? (
                          <Input
                            onChange={handleInputChange}
                            value={editedCompany.gst_number}
                            name="gst_number"
                            defaultValue={editedCompany.gst_number}
                          />
                        ) : (
                          <span>{company.gst_number}</span>
                        )}
                      </Form.Item>
                    </Descriptions.Item>
                    <Descriptions.Item label="Domain">
                      {editMode && editedCompany ? (
                        <Input
                          name="domain"
                          value={editedCompany.domain}
                          onChange={handleInputChange}
                        />
                      ) : (
                        company.domain
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Number of Licence">
                      {company.number_of_licence}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Users">
                      {company.user_count}
                    </Descriptions.Item>
                  </Descriptions>

                  <Descriptions
                    title="Full Address"
                    layout="vertical"
                    className="mt-4"
                    bordered
                  >
                    <Descriptions.Item label="Address Line 1">
                      {editMode && editedCompany ? (
                        <>
                          {/* <Form.Item name={["address", "address1"]}>
                          <Input
                            defaultValue={editedCompany.address.address1}
                            onChange={handleAdress1Change}
                          />
                        </Form.Item> */}
                          <Input
                            name="address1"
                            defaultValue={editedCompany.address.address1}
                            onChange={handleAdress1Change}
                          />
                        </>
                      ) : (
                        company.address.address1
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Address Line 2">
                      {editMode && editedCompany ? (
                        <Input
                          name="address2"
                          defaultValue={editedCompany.address.address2}
                          onChange={handleAddress2Change}
                        />
                      ) : (
                        company.address.address2
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="City">
                      {editMode && editedCompany ? (
                        <Input
                          name="city"
                          defaultValue={editedCompany.address.city}
                          onChange={handleCityChange}
                        />
                      ) : (
                        company && company.address.city
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="PIN">
                      {editMode && editedCompany ? (
                        <Input
                          name="postal_code"
                          defaultValue={editedCompany.address.postal_code}
                          onChange={handlePinChange}
                        />
                      ) : (
                        company && company.address.postal_code
                      )}
                    </Descriptions.Item>

                    <Descriptions.Item label="Country">
                      {editMode && editedCompany ? (
                        <>
                          <Select
                            options={
                              countries &&
                              countries.map((country) => ({
                                // value: state.isoCode,
                                value: country.id,
                                label: country.name,
                              }))
                            }
                            menuPlacement="top"
                            required
                            onChange={handleCountryChange}
                            defaultValue={
                              company &&
                              company.address.country && {
                                value: company.address.country.id,
                                label: company.address.country.name,
                              }
                            }
                          />
                        </>
                      ) : (
                        company &&
                        company.address.country &&
                        company.address.country.name
                      )}
                    </Descriptions.Item>
                    {companyID && (
                      <Descriptions.Item label="State">
                        {editMode && editedCompany ? (
                          <>
                            {/* <AutoComplete
                              options={
                                states &&
                                states.map((state) => ({
                                  // value: state.isoCode,
                                  label: state.name,
                                  value: state.id,
                                }))
                              }
                              // filterOption={(inputValue, option) =>
                              //   option.value
                              //     .toUpperCase()
                              //     .indexOf(inputValue.toUpperCase()) !== -1
                              // }
                              placeholder="Select or type to add State"
                              menuPlacement="top"
                              className="w-full"
                              required
                              onChange={handleStateChange}
                              defaultValue={
                                company &&
                                company.address.state && {
                                  value: company.address.state.id,
                                  label: company.address.state.name,
                                }
                              }
                            /> */}
                            <Select
                              options={
                                states &&
                                states.map((state) => ({
                                  // value: state.isoCode,
                                  value: state.id,
                                  label: state.name,
                                }))
                              }
                              menuPlacement="top"
                              required
                              onChange={handleStateChange}
                              defaultValue={
                                company &&
                                company.address.state && {
                                  value: company.address.state.id,
                                  label: company.address.state.name,
                                }
                              }
                            />
                          </>
                        ) : (
                          company &&
                          company.address.state &&
                          company.address.state.name
                        )}
                      </Descriptions.Item>
                    )}
                    {/* {companyID && (
                      <Descriptions.Item label="State" span={2}>
                        {editMode && editedCompany ? (
                          <>
                            <Select
                              options={
                                states &&
                                states.map((state) => ({
                                  value: state.id,
                                  label: state.name,
                                }))
                              }
                              required
                              onChange={handleStateChange}
                              value={
                                editedCompany.address.state
                                  ? {
                                      value: editedCompany.address.state.id,
                                      label: editedCompany.address.state.name,
                                    }
                                  : null
                              }
                            />
                          </>
                        ) : (
                          editedCompany &&
                          editedCompany.address.state &&
                          editedCompany.address.state.name
                        )}
                      </Descriptions.Item>
                    )} */}
                  </Descriptions>

                  {editMode && editedCompany && (
                    <div className="flex mt-6">
                      <Button onClick={handleCancel} className="">
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="ml-12"
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </TabPane>
                <TabPane tab="Departments" key="2">
                  <div className=" text-black pb-3 px-2 flex items-center justify-between">
                    <span>
                      <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                        Departments
                      </h1>
                    </span>
                    {/* <button className="bg-blue-500 text-white px-4 py-2 rounded">
                    Top Button
                  </button> */}
                    {(user_role && user_role.toLowerCase() === "admin") ||
                    user_role.toLowerCase() === "superadmin" ? (
                      <>
                        <ButtonComp
                          text="Add Departments"
                          bgColor="#4E36A3"
                          textColor="white"
                          onClick={showModal}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {company.department_list &&
                    company.department_list.map((department, index) => (
                      <>
                        <div className="bg-purple-50 py-2 px-5 mb-3">
                          <h1
                            key={department.id}
                            value={department.id}
                            className="mt-1"
                          >
                            <div className="delete_department">
                              <span>{department}</span>
                              {(user_role &&
                                user_role.toLowerCase() === "admin") ||
                              user_role.toLowerCase() === "superadmin" ? (
                                <span>
                                  <Popconfirm
                                    title="Delete Department"
                                    description="Are you sure to delete this department?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() =>
                                      deleteDepartment(department)
                                    }
                                    placement="topRight"
                                  >
                                    <Button danger>
                                      <DeleteOutlined className="" />
                                    </Button>
                                  </Popconfirm>
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </h1>
                        </div>
                      </>
                    ))}

                  <Modal
                    title="Department Name"
                    open={isModalOpen}
                    onCancel={handleCancelModal}
                    footer={null}
                  >
                    <Form
                      form={form}
                      name="dynamic_form_item"
                      {...formItemLayoutWithOutLabel}
                      onFinish={handleDepartment}
                      open={isModalOpen}
                      style={{
                        maxWidth: 600,
                      }}
                    >
                      <Form.List name="department_list">
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {fields.map((field, index) => (
                              <Form.Item
                                {...(index === 200
                                  ? formItemLayout
                                  : formItemLayoutWithOutLabel)}
                                label={""}
                                required={false}
                                key={field.key}
                              >
                                <Form.Item
                                  {...field}
                                  validateTrigger={["onChange", "onBlur"]}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message:
                                        "Please input Department name or delete this field.",
                                    },
                                  ]}
                                  noStyle
                                >
                                  <Input
                                    placeholder="Department name"
                                    style={{
                                      width: "60%",
                                    }}
                                  />
                                </Form.Item>
                                {fields.length > 0 ? (
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button ml-3"
                                    onClick={() => remove(field.name)}
                                  />
                                ) : null}
                              </Form.Item>
                            ))}
                            <Form.Item>
                              <div>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  style={{
                                    width: "60%",
                                  }}
                                  icon={<PlusOutlined />}
                                >
                                  Add Department
                                </Button>
                              </div>
                              <Form.ErrorList errors={errors} />
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                      <Form.Item className="">
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Form>
                  </Modal>
                </TabPane>
                <TabPane tab="Products / Services" key="3">
                  <div className=" text-black pb-3 px-2 flex items-center justify-between">
                    <span>
                      <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                        Products / Services
                      </h1>
                    </span>
                    {/* <button className="bg-blue-500 text-white px-4 py-2 rounded">
                    Top Button
                  </button> */}
                    {(user_role && user_role.toLowerCase() === "admin") ||
                    user_role.toLowerCase() === "superadmin" ? (
                      <ButtonComp
                        text="Add Product / Service"
                        bgColor="#4E36A3"
                        textColor="white"
                        onClick={showProductModal}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <Descriptions.Item
                    label="Product / Service"
                    span={1}
                    // className="text-center"
                  >
                    {products &&
                      products.map((product, index) => (
                        <>
                          <div className="bg-purple-50 py-2 px-5 mb-3">
                            <h1
                              key={product.id}
                              value={product.id}
                              className="mt-1 font-medium"
                            >
                              <div className="delete_department">
                                <span>{product.product_name}</span>
                                {(user_role &&
                                  user_role.toLowerCase() === "admin") ||
                                user_role.toLowerCase() === "superadmin" ? (
                                  <span>
                                    <Popconfirm
                                      title="Delete Product / Service"
                                      description="Are you sure to delete this Product / Service?"
                                      okText="Yes"
                                      cancelText="No"
                                      onConfirm={() =>
                                        deleteProduct(product.product_name)
                                      }
                                    >
                                      <Button danger>
                                        <DeleteOutlined />
                                      </Button>
                                    </Popconfirm>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </h1>
                          </div>
                        </>
                      ))}
                    <Modal
                      title="Product / Service Name"
                      open={isProductModal}
                      onCancel={handleCancelModal}
                      footer={null}
                    >
                      <Form
                        form={form}
                        name="dynamic_form_product"
                        {...formItemLayoutWithOutLabel}
                        onFinish={handleProduct}
                        open={isProductModal}
                        style={{
                          maxWidth: 600,
                        }}
                      >
                        <Form.List name="product_list">
                          {(fields, { add, remove }, { errors }) => (
                            <>
                              {fields.map((field, index) => (
                                <Form.Item
                                  {...(index === 200
                                    ? formItemLayout
                                    : formItemLayoutWithOutLabel)}
                                  label={""}
                                  required={false}
                                  key={field.key}
                                >
                                  <Form.Item
                                    {...field}
                                    validateTrigger={["onChange", "onBlur"]}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message:
                                          "Please input product/service name or delete this field.",
                                      },
                                    ]}
                                    noStyle
                                  >
                                    {products && products ? (
                                      products.map((fields, index) => {
                                        <Input
                                          placeholder="Product / Service Name"
                                          style={{
                                            width: "60%",
                                            marginLeft: "3%",
                                            marginRight: "1%",
                                          }}
                                          defaultValue={fields}
                                        />;
                                      })
                                    ) : (
                                      <Input
                                        placeholder="Product / Service Name"
                                        style={{
                                          width: "60%",
                                          marginLeft: "3%",
                                          marginRight: "1%",
                                        }}
                                      />
                                    )}
                                    <Input
                                      placeholder="Product / Service Name"
                                      style={{
                                        width: "60%",
                                        marginLeft: "3%",
                                        marginRight: "1%",
                                      }}
                                    />
                                  </Form.Item>
                                  {fields.length > 0 ? (
                                    <MinusCircleOutlined
                                      className="dynamic-delete-button ml-3"
                                      onClick={() => remove(field.name)}
                                    />
                                  ) : null}
                                </Form.Item>
                              ))}
                              <Form.Item>
                                <div className="ml-[1rem]">
                                  <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{
                                      width: "60%",
                                    }}
                                    icon={<PlusOutlined />}
                                  >
                                    Add Products / Services
                                  </Button>
                                </div>
                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                        <Form.Item className="">
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </Form.Item>
                      </Form>
                    </Modal>
                  </Descriptions.Item>
                </TabPane>
                <TabPane tab="Custom Fields" key="4">
                  <div className=" text-black pb-3 px-2 flex items-center justify-between">
                    <span>
                      <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                        Custom Fields
                      </h1>
                    </span>
                    {(user_role && user_role.toLowerCase() === "admin") ||
                    user_role.toLowerCase() === "superadmin" ? (
                      <ButtonComp
                        text="Add Custom Fields"
                        bgColor="#4E36A3"
                        textColor="white"
                        onClick={showExtraFieldModal}
                      />
                    ) : (
                      ""
                    )}
                    <Modal
                      title="Extra Fields"
                      open={isExtraFieldModalOpen}
                      onOk={handleOkExtraField}
                      onCancel={handleCancelExtraFieldModal}
                      footer={null}
                    >
                      <Form
                        form={form}
                        name="dynamic_form_nest_item"
                        onFinish={handleFinishExtraField}
                        style={{
                          maxWidth: 600,
                        }}
                        autoComplete="off"
                      >
                        <Form.List name="extra_fields">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <Space
                                  key={key}
                                  style={{
                                    display: "flex",
                                    marginBottom: 8,
                                  }}
                                  align="baseline"
                                >
                                  <Form.Item
                                    {...restField}
                                    name={[name, "field"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing Field name",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Field Name" />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "value"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing value",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Value" />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  />
                                </Space>
                              ))}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add More fields
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </Form.Item>
                      </Form>
                    </Modal>
                  </div>
                  <Descriptions.Item
                    label="Custom Fields"
                    // span={2}
                    className="text-center"
                  >
                    {company.extra_field &&
                      Object.keys(company.extra_field).map((key) => (
                        <>
                          <div className="mb-3">
                            <div
                              key={key}
                              className="extra_field flex flex-col"
                            >
                              <span className="bold rounded bg-purple-50 w-[100%] mr-4 h-12">
                                <h1 className="mt-3 ml-5">{key}</h1>
                              </span>
                              <span className=" bg-purple-50 w-[100%] h-12">
                                <h1 className="mt-3 ml-5">
                                  {company.extra_field[key]}
                                </h1>
                              </span>
                            </div>
                          </div>
                        </>
                      ))}
                  </Descriptions.Item>
                </TabPane>

                <TabPane tab="Users" key="5">
                  <>
                    <div className=" text-black pb-3 px-2 flex items-center justify-between">
                      <span className="flex flex-row items-center">
                        <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                          Users
                        </h1>
                        <CustomSearch onSearch={handleSearch} />
                      </span>
                      {(user_role && user_role.toLowerCase() === "admin") ||
                      user_role.toLowerCase() === "superadmin" ? (
                        <ButtonComp
                          text="Add Users"
                          bgColor="#4E36A3"
                          textColor="white"
                          onClick={showUserFormModal}
                        />
                      ) : (
                        ""
                      )}

                      <Modal
                        title="Add New User"
                        open={userFormModal}
                        onOk={() => handleOk(userId)}
                        onCancel={handleCancel}
                        style={{ fontSize: "24px", padding: "40px" }}
                        okText="Save"
                        okButtonProps={{
                          style: {
                            backgroundColor: "#4E36A3",
                            color: "white",
                            height: "40px",
                            width: "100px",
                          },
                          disabled: loading, // Disable the button when loading is true
                        }} // Set background color for the Ok button
                        cancelButtonProps={{
                          style: {
                            backgroundColor: "",
                            color: "#4E36A3",
                            height: "40px",
                            width: "100px",
                          },
                        }} // Set background color for the Cancel button
                      >
                        {/* <p>{modalText}</p> */}
                        <Form form={form} onFinish={onFinishUser}>
                          <Col>User Name</Col>
                          <Form.Item
                            name="first_name"
                            label=""
                            style={{ marginBottom: 20 }}
                          >
                            <Input placeholder="Enter your username" required />
                          </Form.Item>
                          <Col>Email Address</Col>
                          <Form.Item
                            name="email"
                            label=""
                            style={{ marginBottom: 20 }}
                          >
                            <Input
                              type="email"
                              placeholder="Enter your email address"
                              required
                            />
                          </Form.Item>
                          <Col>Employee ID</Col>
                          <Form.Item
                            name="employee_id"
                            label=""
                            style={{ marginBottom: 20 }}
                          >
                            <Input
                              placeholder="Please enter employee Id"
                              required
                            />
                          </Form.Item>
                          <Col>Department</Col>
                          <Form.Item
                            name="department"
                            label=""
                            style={{ marginBottom: 20 }}
                          >
                            <Select
                              options={
                                allDepartments
                                  ? Object.values(allDepartments).map(
                                      (department) => ({
                                        value: department.id,
                                        label: department.name,
                                      })
                                    )
                                  : []
                              }
                              onChange={(selectedOption) =>
                                setDepartment(selectedOption.value)
                              }
                              required
                            />
                          </Form.Item>
                          <Col>Role</Col>

                          <Form.Item
                            name="role"
                            label=""
                            style={{ marginBottom: 20 }}
                            rules={[
                              {
                                required: true,
                                message: "Please select a Role",
                              },
                            ]}
                          >
                            <Select
                              options={[
                                {
                                  value: "Businessteam",
                                  label: "Business Team",
                                },
                                {
                                  value: "Businesshead",
                                  label: "Business Head",
                                },
                                { value: "Legalteam", label: "Legal Team" },
                                { value: "Legalhead", label: "Legal Head" },
                              ]}
                              onChange={(selectedOption) =>
                                handleRole(selectedOption.value)
                              }
                              required
                            />
                          </Form.Item>

                          {role && role === "Businessteam" && (
                            <>
                              <Col>Business Head Name</Col>
                              <Form.Item
                                name="business_head_id"
                                label=""
                                style={{ marginBottom: 20 }}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please select or add Business Head",
                                  },
                                ]}
                              >
                                <Select
                                  options={
                                    businessHead
                                      ? Object.values(businessHead).map(
                                          (businesshead) => ({
                                            value: businesshead.id,
                                            label: businesshead.first_name,
                                          })
                                        )
                                      : []
                                  }
                                  onChange={(selectedOption) =>
                                    handleBusinessHeadId(selectedOption.value)
                                  }
                                  required
                                />
                              </Form.Item>
                            </>
                          )}
                          {role && role === "Legalteam" && (
                            <>
                              <Col>Legal Head Name</Col>
                              <Form.Item
                                name="business_head_id"
                                label=""
                                style={{ marginBottom: 20 }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select or add Legal Head",
                                  },
                                ]}
                              >
                                <Select
                                  options={
                                    businessHead
                                      ? Object.values(businessHead).map(
                                          (businesshead) => ({
                                            value: businesshead.id,
                                            label: businesshead.first_name,
                                          })
                                        )
                                      : []
                                  }
                                  onChange={(selectedOption) =>
                                    handleBusinessHeadId(selectedOption.value)
                                  }
                                  required
                                />
                              </Form.Item>
                            </>
                          )}
                        </Form>
                      </Modal>
                      <Modal
                        title="Add New Assignee"
                        open={isAssignModal}
                        onOk={() => handleOk(userId)}
                        onCancel={handleCancel}
                        style={{ fontSize: "24px", padding: "40px" }}
                        okText="Save"
                        okButtonProps={{
                          style: {
                            backgroundColor: "#4E36A3",
                            color: "white",
                            height: "40px",
                            width: "100px",
                          },
                        }} // Set background color for the Ok button
                        cancelButtonProps={{
                          style: {
                            backgroundColor: "",
                            color: "#4E36A3",
                            height: "40px",
                            width: "100px",
                          },
                        }} // Set background color for the Cancel button
                      >
                        <Form form={form} onFinish={onFinishAssignee}>
                          <Form.Item
                            name="user_id"
                            label=""
                            style={{ marginBottom: 20 }}
                            rules={[
                              {
                                required: true,
                                message: "Please select a assignee",
                              },
                            ]}
                          >
                            <Select
                              options={
                                assignee
                                  ? Object.values(assignee).map((user) => ({
                                      value: user.id,
                                      label: user.first_name,
                                    }))
                                  : []
                              }
                              onChange={(selectedOption) =>
                                setDepartment(selectedOption.value)
                              }
                              required
                            />
                          </Form.Item>

                          {role && role === "Businessteam" && (
                            <>
                              <Col>Business Head Name</Col>
                              <Form.Item
                                name="business_head_id"
                                label=""
                                style={{ marginBottom: 20 }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Business Head Name",
                                  },
                                ]}
                              >
                                <Select
                                  options={
                                    businessHead
                                      ? Object.values(businessHead).map(
                                          (businesshead) => ({
                                            value: businesshead.id,
                                            label: businesshead.first_name,
                                          })
                                        )
                                      : []
                                  }
                                  onChange={(selectedOption) =>
                                    handleBusinessHeadId(selectedOption.value)
                                  }
                                  required
                                />
                              </Form.Item>
                            </>
                          )}
                          {role && role === "Legalteam" && (
                            <>
                              <Col>Legal Head Name</Col>
                              <Form.Item
                                name="business_head_id"
                                label=""
                                style={{ marginBottom: 20 }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Business Head Name",
                                  },
                                ]}
                              >
                                <Select
                                  options={
                                    businessHead
                                      ? Object.values(businessHead).map(
                                          (businesshead) => ({
                                            value: businesshead.id,
                                            label: businesshead.first_name,
                                          })
                                        )
                                      : []
                                  }
                                  onChange={(selectedOption) =>
                                    handleBusinessHeadId(selectedOption.value)
                                  }
                                  required
                                />
                              </Form.Item>
                            </>
                          )}
                        </Form>
                      </Modal>
                    </div>

                    <div>
                      {loading ? (
                        <div className="text-center top-3 left-3">
                          <LoadingOutlined style={{ fontSize: "5rem" }} />
                        </div>
                      ) : (
                        <Table
                          dataSource={userWithIds}
                          columns={
                            user_role && user_role === "admin"
                              ? userColumnsAdmin
                              : userColumnsElse
                          }
                          className="overflow-y-scroll"
                          scroll={{ x: "max-content" }}
                        />
                      )}
                    </div>
                    <Modal
                      title="Deactivate"
                      open={open}
                      onOk={() => handleOk(userId)}
                      onCancel={handleCancel}
                    >
                      <Form form={form} onFinish={handleSubmit}>
                        <Form.Item
                          name="reason"
                          label="Reason"
                          rules={[
                            {
                              required: true,
                              message: "Please enter deactivation reason.",
                            },
                          ]}
                        >
                          <TextArea />
                        </Form.Item>
                      </Form>
                    </Modal>
                    <Modal
                      title="Activate"
                      open={activeOpen}
                      onOk={() => handleActivate(userId)}
                      onCancel={handleCancel}
                    >
                      <p>{modalText}</p>
                    </Modal>
                  </>
                </TabPane>
                <TabPane tab="Download" key="6">
                  <div className="app">
                    <div className="financialYearsContainer">
                      {financialYears.map((financialYear) => (
                        <FinancialYearFolder
                          key={financialYear.year}
                          financialYear={financialYear.year}
                          handleMonthSelect={fetchExecutedContracts}
                        />
                      ))}
                    </div>
                  </div>
                  {executedContracts && (
                    <Spin spinning={loading} tip="Loading...">
                      <Table
                        columns={columns}
                        dataSource={executedContracts}
                        // onChange={onChange}
                        scroll={{ x: "max-content" }}
                      />
                    </Spin>
                  )}
                </TabPane>
                {user_role === "admin" && (
                  <TabPane tab="Bulk Upload" key="7">
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload. You can choose
                        single or multiple file to upload.
                      </p>
                    </Dragger>
                  </TabPane>
                )}
                {user_role === "superadmin" && (
                  <TabPane tab="Companies" key="7">
                    <>
                      <div className=" text-black pb-3 px-2 flex items-center justify-between">
                        <span className="flex flex-row items-center">
                          <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                            Companies
                          </h1>
                          <CustomSearch onSearch={handleCompanySearch} />
                        </span>

                        <ButtonComp
                          text="Add Companies"
                          bgColor="#4E36A3"
                          textColor="white"
                          onClick={showCompanyCreateModal}
                        />

                        <Modal
                          title="Add Company"
                          open={isCompanyCreateModalOpen}
                          onOk={handleOkCompanyCreate}
                          onCancel={handleCancel}
                          style={{ fontSize: "24px", padding: "40px" }}
                          okText="Create"
                          okButtonProps={{
                            style: {
                              backgroundColor: "#4E36A3",
                              color: "white",
                              height: "40px",
                              width: "100px",
                            },
                            disabled: loading, // Disable the button when loading is true
                          }} // Set background color for the Ok button
                          cancelButtonProps={{
                            style: {
                              backgroundColor: "",
                              color: "#4E36A3",
                              height: "40px",
                              width: "100px",
                            },
                          }} // Set background color for the Cancel button
                        >
                          {/* <p>{modalText}</p> */}
                          <Form form={form} onFinish={handleCompanyCreate}>
                            <Col>Name of User</Col>

                            <Form.Item label="" name="first_name">
                              <Input placeholder="" />
                            </Form.Item>
                            <Col>Company Name</Col>

                            <Form.Item label="" name="name">
                              <Input placeholder="" />
                            </Form.Item>
                            <Col>Email Address</Col>
                            <Form.Item label="" name="admin_email">
                              <Input placeholder="" />
                            </Form.Item>
                            <Col>Contact Number</Col>

                            <Form.Item label="" name="admin_contact_number">
                              <Input
                                placeholder=""
                                pattern="[0-9]{10}"
                                title="Please enter a 10-digit contact number."
                              />
                            </Form.Item>
                            <Col>Country</Col>

                            <Form.Item label="" name="country">
                              <Select
                                placeholder="Select Country"
                                onChange={handleCountryChange}
                                menuPlacement="top"
                                options={
                                  countries &&
                                  countries.map((country) => ({
                                    // value: state.isoCode,
                                    value: country.id,
                                    label: country.name,
                                  }))
                                }
                              />
                            </Form.Item>
                            {/* <Form.Item>
                              <Button
                                loading={isLoading}
                                type="submit"
                                htmlType="submit"
                              >
                                {isLoading ? "Creating..." : "Add"}
                              </Button>
                            </Form.Item> */}
                          </Form>
                        </Modal>
                      </div>

                      <div>
                        {loading ? (
                          <div className="text-center top-3 left-3">
                            <LoadingOutlined style={{ fontSize: "5rem" }} />
                          </div>
                        ) : (
                          <Table
                            dataSource={allCompanies}
                            columns={companiesColumns}
                            className="overflow-y-scroll"
                            scroll={{ x: "max-content" }}
                          />
                        )}
                      </div>
                      <Modal
                        title="Deactivate"
                        open={deactivateModal}
                        onOk={() => handleOk(userId)}
                        onCancel={handleCancel}
                      >
                        <Form
                          form={form}
                          onFinish={() => handleCompanyStatus("deactivate")}
                        >
                          <Form.Item
                            name="reason"
                            label="Reason"
                            rules={[
                              {
                                required: true,
                                message: "Please enter deactivation reason.",
                              },
                            ]}
                          >
                            <TextArea />
                          </Form.Item>
                        </Form>
                      </Modal>
                      <Modal
                        title="Activate"
                        open={activeOpen}
                        onOk={() => handleCompanyActivate(companyId)}
                        onCancel={handleCancel}
                      >
                        <p>{modalText}</p>
                      </Modal>
                    </>
                  </TabPane>
                )}
              </Tabs>
            </Form>
            <div
              style={{
                position: "relative",
                bottom: 0,
                left: 0,
                // width: "105.1%",

                display: "flex",
                justifyContent: "flex-start",
                // backgroundColor: "#EBE8F7",
                paddingTop: "30px",
              }}
            >
              {activeTab !== "1" && (
                <ButtonComp
                  text="Previous"
                  textColor="#4E36A3"
                  bgColor="white"
                  onClick={handlePrevious}
                  margin="2rem"
                />
              )}
              {parseInt(activeTab) !== parseInt(finalPage) ? (
                <>
                  {editMode ? (
                    ""
                  ) : (
                    <ButtonComp
                      text="Next"
                      bgColor="#4E36A3"
                      textColor="white"
                      onClick={handleNext}
                    />
                  )}
                </>
              ) : (
                <>
                  {editMode ? (
                    ""
                  ) : (
                    <ButtonComp
                      text="Finish"
                      bgColor="#4E36A3"
                      textColor="white"
                      onClick={handleNext}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div>
        <DocumentEditor
          id="docxEditor"
          documentServerUrl="http://documentserver/"
          config={{
            document: {
              fileType: "docx",
              key: "Khirz6zTPdfd7",
              title: "Example Document Title.docx",
              url: "https://example.com/url-to-example-document.docx",
            },
            documentType: "word",
            editorConfig: {
              callbackUrl: "https://example.com/url-to-callback.ashx",
            },
          }}
        />
      </div>
    </>
  );
}

export default SettingsPage;
