import React, { useState, useEffect } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import axios from "../../utils/axios";
import { API_URL } from "../../utils/Constant";
import { useNavigate } from "react-router-dom";
import { capitalizeAndRemoveUnderscores, limitWords } from "../../utils/utils";
import {
  QuestionCircleOutlined,
  UploadOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import {
  Descriptions,
  Input,
  Button,
  message,
  DatePicker,
  Popconfirm,
  Form,
  Modal,
  Card,
  Row,
  Col,
  Upload,
  Table,
  Tabs,
  Tooltip,
  Select,
  FloatButton,
  Popover,
  Space,
  Spin,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import { agreementOptions } from "../../utils/Constant";
import ButtonComp from "../../components/button/ButtonComp";
import { formatIndianDate } from "../../utils/Constant";
import { BiEditAlt } from "react-icons/bi";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaDownload } from "react-icons/fa6";
import { CiMenuKebab } from "react-icons/ci";
import { LuClipboardEdit } from "react-icons/lu";
import { FiSend } from "react-icons/fi";
import { MdAssignmentInd } from "react-icons/md";
import { LuSendHorizonal } from "react-icons/lu";
import { BiSend } from "react-icons/bi";
import { editableInputTypes } from "@testing-library/user-event/dist/utils";
import { invoiceFrequencyOptions } from "../../utils/Constant";
import CreatableSelect from "react-select/creatable";
import { toTitleCase } from "../../utils/utils";
import { AddOutlined } from "@mui/icons-material";
import SelectComponent from "../../components/select/SelectComponent";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { findMaxIndex } from "../../utils/utils";
import { MdUploadFile } from "react-icons/md";
import MasterTemplateModal from "../masterTemplate/MasterTempModal";
import { RiGalleryUploadLine } from "react-icons/ri";
import ClausesModal from "../clauseTemplate/clauseTemplateModal";
import { ImSpinner3 } from "react-icons/im";
import { Typography } from "antd";
import { FaFileSignature } from "react-icons/fa";
import { getAllISOCodes } from "iso-country-currency";

const { Text } = Typography;

dayjs.extend(customParseFormat);
const dateFormat = "DD-MM-YYYY";
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

function ContractDetailComp() {
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const params = useParams();
  const [contract, setContract] = useState();

  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMasterLoading, setIsMasterLoading] = useState(false);
  const [isClauseLoading, setIsClauseLoading] = useState(false);
  const [clausesData, setClausesData] = useState([]);
  const [masterClausesData, setMasterClausesData] = useState([]);
  const [countries, setCountries] = useState();
  const [states, setStates] = useState();
  const [clientStates, setClientStates] = useState();
  const [partyStates, setPartyStates] = useState();
  const [uploading, setUploading] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [masterTempUploading, setMasterTempUploading] = useState(false);

  const [editedContract, setEditedContract] = useState({});
  const [isSendModalVisible, setIsSendModalVisible] = useState(false);
  const [isThirdPartyVisible, setIsThirdPartyVisible] = useState(false);
  const [isDifferentUser, setIsDifferentUser] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [masterTempModal, setMasterTempModal] = useState(false);
  const [clauseTempModal, setClauseTempModal] = useState(false);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [companyDiffUsers, setCompanyDiffUsers] = useState([]);
  const [masterTemplateData, setMasterTemplateData] = useState([]);
  const [templateData, setTemplateData] = useState([]);

  const [customFields, setCustomFields] = React.useState([]);

  const [attachments, setAttachments] = useState();
  const [attachmentModal, setAttachmentModal] = useState(false);

  const [fileName, setFilename] = useState();
  const [selectedStatus, setSelectedStatus] = useState();

  const [productList, setProductList] = useState([]);
  const [productEditMode, setProductEditMode] = useState(false);
  const [productModal, setProductModal] = useState(false);
  const [customModal, setCustomModal] = useState(false);

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [data, setData] = useState([]);

  const [formFields, setFormFields] = useState([{ id: 1, value: "" }]);

  const [finalPage, setFinalPage] = useState(6);
  const [activeTab, setActiveTab] = useState("1");

  const [isUserAddModalVisible, setIsUserAddModalVisible] = useState(false);
  const [dueDate, setDueDate] = useState(contract && contract.payment_due_date);
  const [effectiveDate, setEffectiveDate] = useState(
    contract && contract.effective_date
  );
  const [executionDate, setExecutionDate] = useState(
    contract && contract.date_of_execution
  );
  const [signuluResponse, setSignuluResponse] = useState({});
  const queryParams = new URLSearchParams(window.location.search);
  const referrer = queryParams.get("referrer");
  const [esignDetailModalOpen, setEsignDetailModalOpen] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [isModifyOpen, setModifyOpen] = useState(false);

  const [form] = Form.useForm();
  const showUserAddModal = () => {
    setIsUserAddModalVisible(true);
  };
  const handleOk = () => {
    form.submit();
  };

  const handleModalCancel = () => {
    setIsUserAddModalVisible(false);
    setIsSendModalVisible(false);
    setModalVisible(false);
    setIsThirdPartyVisible(false);
    setIsDifferentUser(false);
    setProductModal(false);
    setAttachmentModal(false);
    setCustomModal(false);
    setMasterTempModal(false);
    setFormFields([{ id: 1, value: "" }]);

    form.resetFields();
    setSelectedProducts([]);
  };

  const handleAddUserSubmit = (values) => {
    if (!values.hasOwnProperty("assign")) {
      values.assign = true;
    }

    axios
      .put(API_URL + `/contract/${params.pk}/assign/`, values, config)
      .then((response) => {
        // Handle successful response
        setEditMode(false);
        message.success(response.data.message);
        fetchContracts();
      })
      .catch((error) => {});

    setEditMode(false);
    setIsUserAddModalVisible(false);
  };

  const handleReceiversEmailChange = (values) => {
    // const selectedValues = Object.values(values).map((item) => item.value);
    // setSelectedEmails(selectedValues);
  };

  const userRole = localStorage.getItem("user_role");
  const role = userRole && userRole.toLowerCase();

  let statusOptions = [];
  if (contract) {
    statusOptions = [
      { value: "FRESH_REQUEST", label: "Fresh Request" },
      { value: "UNDER_NEGOTIATION", label: "Under Negotiation" },
      { value: "UNDER_EXECUTION", label: "Under Execution" },
      ...(role && (role === "legalteam" || role === "legalhead")
        ? [{ value: "EXECUTED", label: "Executed" }]
        : []),
      ...(localStorage.getItem("user_email") === contract.created_by
        ? [{ value: "TRANSACTION_CLOSED", label: "Transaction Closed" }]
        : []),
      //   ? [{ value: "TRANSACTION_CLOSED", label: "Transaction Closed" }]
      //   : []),
    ];
  }

  if (role === "businessteam") {
    statusOptions = statusOptions.filter(
      (option) => option.value !== "EXECUTED"
    );
  }
  const selectedStatusOption = (value) => {
    setSelectedStatus(value);
  };

  const natureOptions = [
    { value: "customer", label: "Customer" },
    { value: "vendor", label: "Vendor" },
    { value: "partnership", label: "Partnership" },
  ];

  const countryCurrency = async () => {
    try {
      const codes = await getAllISOCodes();
      const transformedData = codes.map((code) => ({
        value: `${code.symbol}`,
        label: `${code.countryName} (${code.symbol})`,
      }));
      console.log("errorCheck", codes, transformedData);

      setCurrencyOptions(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };
  const handleProductEdit = () => {
    setProductEditMode(true);
  };
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(API_URL + "/country/get/");
        setCountries(response.data); // Update the state with the fetched user data
      } catch (error) {}
    };
    fetchCountry();
    countryCurrency();
  }, []);

  const fetchState = async (value, type) => {
    try {
      const response = await axios.get(API_URL + "/state/get/", {
        params: {
          country_id: value,
        },
      });
      if (type === "all") {
        setStates(response.data); // Update the state with the fetched user data
      } else if (type === "client") {
        setClientStates(response.data);
      } else if (type === "party") {
        setPartyStates(response.data);
      }
    } catch (error) {}
  };

  const handleCancel = () => {
    setEditMode(false);
    setProductEditMode(false);
  };

  const dataCopy = { ...editedContract };
  delete dataCopy.company;
  delete dataCopy.agreement;
  delete dataCopy.contract_access_users;
  delete dataCopy.created_by;
  delete dataCopy.created_at;
  delete dataCopy.date_of_execution;
  delete dataCopy.expiry_date;
  delete dataCopy.id;
  delete dataCopy.modified_at;
  delete dataCopy.name_of_client;
  delete dataCopy.party;
  delete dataCopy.party_name;
  delete dataCopy.term_sheet;
  delete dataCopy.user_name;

  const countryId =
    dataCopy &&
    dataCopy.party_address &&
    dataCopy.party_address.country &&
    dataCopy.party_address.country.id;

  const stateId =
    dataCopy &&
    dataCopy.party_address &&
    dataCopy.party_address.state &&
    dataCopy.party_address.state.id;

  const clientCountryId =
    dataCopy &&
    dataCopy.client_address &&
    dataCopy.client_address.country &&
    dataCopy.client_address.country.id;

  const clientStateId =
    dataCopy &&
    dataCopy.client_address &&
    dataCopy.client_address.state &&
    dataCopy.client_address.state.id;

  const newData = {
    ...dataCopy,
    party_address: {
      ...dataCopy.party_address,
      country: countryId,
      state: stateId,
    },

    client_address: {
      ...(dataCopy.client_address || {}),
      ...(clientCountryId && { country: clientCountryId }),
      ...(clientStateId && { state: clientStateId }),
      ...(dataCopy.client_address?.address1 && {
        address1: dataCopy.client_address.address1,
      }),
      ...(dataCopy.client_address?.address2 && {
        address2: dataCopy.client_address.address2,
      }),
      ...(dataCopy.client_address?.city && {
        city: dataCopy.client_address.city,
      }),
      ...(dataCopy.client_address?.postal_code && {
        postal_code: dataCopy.client_address.postal_code,
      }),
    },
    effective_date: effectiveDate && effectiveDate,
    date_of_execution: executionDate && executionDate,
    payment_due_date: dueDate && dueDate,
  };
  const esignColumns = [
    {
      title: "Signed By",
      dataIndex: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => (
        <>
          <Tooltip title={text}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {limitWords(text, 100)}
            </div>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Signature Image",
      dataIndex: "signatureImage",
      ellipsis: true, // Enable ellipsis for the column
      render: (text) => <img src={text} alt="sign" className="h-20" />,
    },
    {
      title: "Signature Status",
      dataIndex: "status",
      render: (text) => capitalizeAndRemoveUnderscores(text),
    },
  ];

  const columns = [
    {
      title: "Modified By",
      dataIndex: "modification_user_name",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      ellipsis: true, // Enable ellipsis for the column
      render: (text) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 9)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Current Status",
      dataIndex: "current_status",
      render: (text) => capitalizeAndRemoveUnderscores(text),
    },
    {
      title: "Previous Status",
      dataIndex: "previous_status",
      render: (text) => capitalizeAndRemoveUnderscores(text),
    },
    {
      title: (
        <div>
          Modified On Date <br />
        </div>
      ),
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => formatIndianDate(date),
    },
    {
      title: "Current Version",
      dataIndex: "agreement",
      render: (text, record) => (
        <>
          <Button
            onClick={() => handleViewClick(record.current_agreement)}
            type="submit"
            className="inline-block rounded-full border-2 border-success px-6 pb-[6px] pt-2 bg-green-500 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-success-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-success-600 focus:border-success-600 focus:text-black focus:outline-none focus:ring-0 active:border-success-700 active:text-black-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            View
          </Button>
        </>
      ),
    },
    {
      title: "Previous Version",
      dataIndex: "agreement",
      render: (text, record) => (
        <>
          <Button
            onClick={() => handleViewClick(record.previous_agreement)}
            type="submit"
            className="inline-block rounded-full border-2 border-success px-6 pb-[6px] pt-2 bg-green-500 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-success-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-success-600 focus:border-success-600 focus:text-black focus:outline-none focus:ring-0 active:border-success-700 active:text-black-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
          >
            View
          </Button>
        </>
      ),
    },
  ];

  let customFieldsValue = [];
  if (contract && contract.custom_field) {
    customFieldsValue = Object.keys(contract.custom_field)
      .filter((key) => key.includes("field"))
      .map((key) => ({
        field: key.includes("field") ? contract.custom_field[key] : "",
      }));
  }
  let customValues = [];
  if (contract && contract.custom_field) {
    customValues = Object.keys(contract.custom_field)
      .filter((key) => key.includes("value"))
      .map((key) => ({
        value: key.includes("value") ? contract.custom_field[key] : "",
      }));
  }

  const combinedCustomList = customFieldsValue.map((fieldObj, index) => ({
    field: fieldObj.field,
    value: customValues[index].value,
  }));

  const customTabColumns = [
    {
      title: "Fields",
      dataIndex: "field",
      render: (_, record, index) => (
        <>
          {productEditMode && editedContract ? (
            <Input
              name={"field"}
              // value={record.value}
              defaultValue={record.field}
              onChange={(e) =>
                handleCustomFormChange(e.target.value, index, e.target.name)
              }
            />
          ) : (
            <h1>{record.field}</h1>
          )}
        </>
      ),
    },

    {
      title: "Values",
      dataIndex: "value",
      render: (_, record, index) => (
        <>
          {productEditMode && editedContract ? (
            <Input
              name={"value"}
              // value={record.value}
              defaultValue={record.value}
              onChange={(e) =>
                handleCustomFormChange(e.target.value, index, e.target.name)
              }
            />
          ) : (
            <h1>{record.value}</h1>
          )}
        </>
      ),
    },
  ];

  const handleAddRow = (index) => {
    const clickedProduct = data[index];
    const newData = [...data];

    const newRow = {
      product_name: clickedProduct.product_name, // Add other properties and set them to initial values
      invoice_frequency: null,
      credit_term: null,
      invoice_raising_date: null,
      value: null,
      custom_fields: null,
    };

    newData.splice(index + 1, 0, newRow);

    setData(newData);
  };
  const handleRemoveRow = (index) => {
    // Remove the row at the specified index
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
  };

  const productColumn = [
    {
      title: "Products / Services",
      // dataIndex: "product",
      render: (_, record, index) => (
        <>
          <h1>{record.product_name}</h1>
        </>
      ),
    },

    {
      title: (
        <>
          Invoice Frequency{" "}
          <Tooltip title="One Time/ Monthly/ Quarterly/ Half Yearly  ">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "invoice_frequency",
      render: (_, record, index) => (
        <>
          <SelectComponent
            value={
              record.invoice_frequency &&
              invoiceFrequencyOptions.find(
                (freq) => freq.value === record.invoice_frequency
              )
            }
            isClearable
            options={invoiceFrequencyOptions}
            onChange={(selectedOption) =>
              handleProductInputChange(
                index,
                "invoice_frequency",
                selectedOption
              )
            }
          ></SelectComponent>
        </>
      ),
    },
    {
      title: (
        <>
          Invoice Raising Date{" "}
          <Tooltip title="7th of every succeeding month/ 7th of every month.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "invoice_raising_date",
      render: (_, record, index) => (
        <>
          <Input
            value={record.invoice_raising_date}
            onChange={(e) =>
              handleProductInputChange(
                index,
                "invoice_raising_date",
                e.target.value
              )
            }
          />
        </>
      ),
    },
    {
      title: (
        <>
          Credit Term{" "}
          <Tooltip title="Maximum Credit Period Allowed">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "credit_term",
      render: (_, record, index) => (
        <Input
          value={record.credit_term}
          onChange={(e) =>
            handleProductInputChange(index, "credit_term", e.target.value)
          }
        />
      ),
    },
    {
      title: (
        <>
          Credit Term{" "}
          <Tooltip title="Maximum Credit Period Allowed">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "credit_term",
      render: (_, record, index) => (
        <Input
          value={record.credit_term}
          onChange={(e) =>
            handleInputChange(index, "credit_term", e.target.value)
          }
        />
      ),
    },

    {
      title: (
        <>
          Contract Currency{" "}
          <Tooltip title="Select the currency of contract.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "contract_currency",
      render: (_, record, index) => (
        <div className="w-52">
          <Select
            key={`record-${record.contract_currency || index}`}
            styles={{
              menu: (base) => ({ ...base, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
            defaultValue={() =>
              record?.contract_currency &&
              currencyOptions.find(
                (option) =>
                  option.value.toLowerCase() ===
                  record.contract_currency.toLowerCase()
              )
            }
            // isClearable
            options={currencyOptions}
            onChange={(selectedOption) =>
              handleInputChange(
                index,
                "contract_currency",
                selectedOption.value
              )
            }
          />
        </div>
      ),
    },
    {
      title: (
        <>
          Contract Currency Value{" "}
          <Tooltip title="Converted currency value.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "contract_currency_value",
      render: (_, record, index) => (
        <Input
          disabled={
            record.contract_currency === localStorage.getItem("currency")
          }
          value={record.contract_currency_value}
          className="disabled:bg-transparent text-black"
          onChange={(e) =>
            handleInputChange(index, "contract_currency_value", e.target.value)
          }
        />
      ),
    },
    {
      title: (
        <>
          Conversion Rate{" "}
          <Tooltip title="Enter conversion rate from base currency to contract currency.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "conversion_rate",
      render: (_, record, index) => (
        <Input
          disabled={
            !(record.contract_currency && record.contract_currency_value) ||
            record.contract_currency === localStorage.getItem("currency")
          }
          value={record.conversion_rate}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (!/^\d*\.?\d*$/.test(inputValue)) {
              // Display a message or take any action for invalid input
              message.error("Please enter only numerical value.");
              return;
            }
            const contract_currency_value = (
              record.contract_currency_value * e.target.value
            ).toFixed(2);
            handleInputChange(index, "conversion_rate", inputValue);
            handleInputChange(index, "value", contract_currency_value);
          }}
        />
      ),
    },

    {
      title: (
        <>
          Base Currency Value{" "}
          <Tooltip title="Total Contract Value">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "value",
      render: (_, record, index) => (
        <Input
          disabled
          value={record.value}
          // onChange={(e) => handleInputChange(index, "value", e.target.value.replace(/[^0-9]/g, ''))}
          // onChange={(e) => {
          //   const inputValue = e.target.value;
          //   if (!/^\d*\.?\d*$/.test(inputValue)) {
          //     // Display a message or take any action for invalid input
          //     message.error("Please enter only numerical value.");
          //     return;
          //   }
          //   handleInputChange(index, "value", inputValue);
          // }}
          prefix={
            localStorage.getItem("currency") !== "null"
              ? localStorage.getItem("currency")
              : ""
          }
          className="disabled:bg-transparent text-black"
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record, index) => (
        <>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleAddRow(index)}
            style={{ marginRight: 8 }}
          />
          {data.filter((item) => item.product_name === record.product_name)
            .length > 1 && (
            <Button
              type="danger"
              icon={<MinusOutlined />}
              onClick={() => handleRemoveRow(index)}
            />
          )}
        </>
      ),
    },
  ];

  const productColumns = [
    {
      title: "Products / Services",
      render: (_, record, index) => (
        <>
          <h1>{record.product_name || record.custom_product}</h1>
        </>
      ),
    },
    {
      title: (
        <>
          Invoice Frequency{" "}
          <Tooltip title="One Time/ Monthly/ Quarterly/ Half Yearly">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "invoice_frequency",
      render: (_, record, index) => (
        <>
          {productEditMode && editedContract ? (
            <Select
              defaultValue={record.invoice_frequency}
              onChange={(value) =>
                handleProductChange(value, index, "invoice_frequency")
              }
              name="invoice_frequency"
              autoWidth
              // style={{ width: "100%" }}
              optionLabelProp="label"
              className="custom-dropdown w-full"
            >
              {invoiceFrequencyOptions &&
                invoiceFrequencyOptions.map((inv) => (
                  <Select.Option
                    key={inv.value}
                    value={inv.value}
                    defaultValue={record.invoice_frequency}
                  >
                    {inv.label}
                  </Select.Option>
                ))}
            </Select>
          ) : (
            <h1>{record.invoice_frequency}</h1>
          )}
        </>
      ),
    },

    {
      title: (
        <div>
          Invoice Raising Date <br />
        </div>
      ),
      dataIndex: "invoice_raising_date",
      key: "invoice_raising_date",
      render: (_, record, index) => (
        <>
          {productEditMode && editedContract ? (
            <Input
              name={"invoice_raising_date"}
              // value={record.value}
              defaultValue={record.invoice_raising_date}
              onChange={(e) =>
                handleProductChange(e.target.value, index, e.target.name)
              }
            />
          ) : (
            <h1>{record.invoice_raising_date}</h1>
          )}
        </>
      ),
    },
    {
      title: (
        <>
          Credit Term{" "}
          <Tooltip title="Maximum Credit Period Allowed">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "credit_term",
      render: (_, record, index) => (
        <>
          {productEditMode && editedContract ? (
            <Input
              name={"credit_term"}
              // value={record.value}
              defaultValue={record.credit_term}
              onChange={(e) =>
                handleProductChange(e.target.value, index, e.target.name)
              }
            />
          ) : (
            <h1>{record.credit_term}</h1>
          )}
        </>
      ),
    },

    {
      title: (
        <>
          Contract Currency{" "}
          <Tooltip title="Select the currency of contract.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "contract_currency",
      render: (_, record, index) => (
        <div className="w-52">
          {productEditMode && editedContract ? (
            <Select
              key={`record-${record.contract_currency || index}`}
              styles={{ width: "100%" }}
              className="w-52"
              menuPortalTarget={document.body}
              defaultValue={() =>
                record?.contract_currency &&
                currencyOptions.find(
                  (option) =>
                    option.value.toLowerCase() ===
                    record.contract_currency?.split("+")[1].toLowerCase()
                )
              }
              // isClearable
              options={currencyOptions}
              onChange={(selectedOption) =>
                handleInputChange(
                  index,
                  "contract_currency",
                  selectedOption.value
                )
              }
            />
          ) : (
            <h1>{record.contract_currency?.split("+")[1]}</h1>
          )}
        </div>
      ),
    },
    {
      title: (
        <>
          Contract Currency Value{" "}
          <Tooltip title="Converted currency value.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "contract_currency_value",
      render: (_, record, index) => (
        <>
          {productEditMode && editedContract ? (
            <Input
              // disabled
              value={record.contract_currency_value}
              className="disabled:bg-transparent text-black"
              onChange={(e) => {
                const contract_currency_value = (
                  record.contract_currency_value * e.target.value
                ).toFixed(2);
                handleInputChange(
                  index,
                  "contract_currency_value",
                  e.target.value
                );
                handleInputChange(index, "value", contract_currency_value);
              }}
            />
          ) : (
            <h1>{record.contract_currency_value}</h1>
          )}
        </>
      ),
    },
    {
      title: (
        <>
          Conversion Rate{" "}
          <Tooltip title="Enter conversion rate from base currency to contract currency.">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "conversion_rate",
      render: (_, record, index) => (
        <>
          {productEditMode && editedContract ? (
            <Input
              disabled={
                !(record.contract_currency && record.value) ||
                record.contract_currency === localStorage.getItem("currency")
              }
              value={record.conversion_rate}
              // onChange={(e) => handleInputChange(index, "value", e.target.value.replace(/[^0-9]/g, ''))}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (!/^\d*\.?\d*$/.test(inputValue)) {
                  // Display a message or take any action for invalid input
                  message.error("Please enter only numerical value.");
                  return;
                }
                const contract_currency_value = record.value * e.target.value;
                handleInputChange(index, "conversion_rate", inputValue);
                handleInputChange(
                  index,
                  "contract_currency_value",
                  contract_currency_value
                );
              }}
            />
          ) : (
            <h1>{record.conversion_rate}</h1>
          )}
        </>
      ),
    },

    {
      title: (
        <>
          Base Currency Value{" "}
          <Tooltip title="Total Contract Value">
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "value",
      render: (_, record, index) => (
        <>
          {productEditMode && editedContract ? (
            <Input
              name={"value"}
              value={record.value}
              disabled
              defaultValue={record.value}
              // onChange={(e) =>
              //   handleProductChange(e.target.value, index, e.target.name)
              // }
              prefix={
                localStorage.getItem("currency") !== "null"
                  ? localStorage.getItem("currency")
                  : ""
              }
            />
          ) : (
            <h1>
              {localStorage.getItem("currency") !== "null"
                ? localStorage.getItem("currency")
                : ""}{" "}
              {record.value ? record.value : 0}
            </h1>
          )}
        </>
      ),
    },
  ];

  const handleSave = (data) => {
    axios
      .patch(
        API_URL + `/contract/${params.pk}/update/`,
        // { data: JSON.stringify(newData) },
        { data: data ? data : newData },
        config
      )
      .then((response) => {
        // Handle successful response
        setEditMode(false);
        setProductEditMode(false);
        setProductModal(false);
        setCustomFields([]);
        setCustomModal(false);

        // setProductEditMode(false);
        message.success("Contract Updated Successfully");
        fetchContracts();
      })
      .catch((error) => {
        setProductEditMode(false);
        setEditMode(false);
        setCustomModal(false);

        // Handle error
        console.error("Error updating user details:", error);
        message.error("An error occured updating contract.");
        setProductEditMode(false);
        setCustomFields([]);

        // setEditMode(false);
      });

    // setEditMode(false);
  };

  const handleCustomFieldChange = (index, field, value) => {
    const updatedFields = [...customFields];
    updatedFields[index] = { field, value };
    setCustomFields(updatedFields);
  };
  const handleAddCustomField = () => {
    setCustomFields([...customFields, { field: "", value: "" }]);
  };

  const handleRemoveCustomField = (index) => {
    const updatedFields = [...customFields];
    updatedFields.splice(index, 1);
    setCustomFields(updatedFields);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedContract((prevContract) => ({
      ...prevContract,
      [name]: value,
    }));
  };

  const handleProductInputChange = (index, columnName, value) => {
    const newData = [...data];
    newData[index][columnName] = value;
    setData(newData);
  };

  let updatedProductList = [];

  const handleProductChange = (value, index, name) => {
    // Create a copy of the product list
    updatedProductList = [...contract.product_list];
    // const updatedProductList = [...productList];

    updatedProductList[index] = {
      ...updatedProductList[index],
      [name]: value,
    };
    setContract({
      ...contract,
      product_list: updatedProductList,
    });
  };

  let updatedCustomForm = contract ? contract.custom_field : {};

  const handleCustomFormChange = (value, index, name) => {
    // Create a copy of the product list
    const updatedCustomForm = contract.custom_field;
    const fieldName = `field${index + 1}`;
    const valueName = `value${index + 1}`;
    if (name === "field") {
      // Update field3 based on field index
      updatedCustomForm[fieldName] = value;
    } else if (name === "value") {
      // Update field3 based on value index
      updatedCustomForm[valueName] = value;
    }
  };
  useEffect(() => {
    if (contract) {
      setProductList(contract.product_list);
    }
  }, [contract]);

  const handleAddressChange = (e, addressType, select, type) => {
    const { name, value } = e.target;
    if (name === "country") {
      if (addressType === "client_address") {
        fetchState(value, "client");
      } else if (addressType === "party_address") {
        fetchState(value, "party");
      }
    }

    setEditedContract((prevAddress) => {
      // Initialize prevAddress if it's null or undefined
      if (!prevAddress) {
        return {
          [addressType]:
            select === "select" ? { [type]: { id: value } } : { [name]: value },
        };
      }

      // Initialize prevAddress[addressType] if it's null or undefined
      const updatedAddressType = prevAddress[addressType]
        ? { ...prevAddress[addressType] }
        : {};

      // Initialize prevAddress[addressType][type] if it's null or undefined
      if (select === "select") {
        updatedAddressType[type] =
          prevAddress[addressType] && prevAddress[addressType][type]
            ? { ...prevAddress[addressType][type], id: value }
            : { id: value };
      } else {
        updatedAddressType[name] = value;
      }

      return {
        ...prevAddress,
        [addressType]: updatedAddressType,
      };
    });
  };

  const handleAgreementChange = (value) => {
    setEditedContract((prevCompany) => ({
      ...prevCompany,
      agreement_type: value,
    }));
  };

  const handleNatureChange = (value) => {
    setEditedContract((prevCompany) => ({
      ...prevCompany,
      nature: value,
    }));
  };

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  const fetchContracts = async () => {
    try {
      const response = await axios.get(
        API_URL + `/contract/${params.pk}/get/`,
        config
      );
      setContract(response.data);
      setEditedContract(response.data);
    } catch (error) {}
  };
  useEffect(() => {
    fetchContracts();
  }, []);
  useEffect(() => {
    if (
      contract &&
      contract.client_address &&
      contract.client_address.country &&
      contract.client_address.country.id
    ) {
      fetchState(contract.client_address.country.id, "client");
      // fetchState(contract.party_address.country.id, 'party');
    }
    if (
      contract &&
      contract.party_address &&
      contract.party_address.country &&
      contract.party_address.country.id
    ) {
      fetchState(contract.party_address.country.id, "party");
      // fetchState(contract.party_address.country.id, 'party');
    }
  }, [contract]);

  const [errorMessageShown, setErrorMessageShown] = useState(false);

  const handleViewClick = (link) => {
    if (link) {
      window.open(link, "_blank");
      // Reset error message state
      setErrorMessageShown(false);
    } else {
      // Show error message only if it hasn't been shown already
      if (!errorMessageShown) {
        message.error("Document does not exist.");
        setErrorMessageShown(true);

        // Automatically reset error message state after 5 seconds (5000 milliseconds)
        setTimeout(() => {
          setErrorMessageShown(false);
        }, 5000);
      }
    }
  };

  const handlePdfDownload = (agreeementPdf) => {
    if (agreeementPdf) {
      window.open(agreeementPdf, "_blank");
      // Reset error message state
      setErrorMessageShown(false);
    } else {
      // Show error message only if it hasn't been shown already
      if (!errorMessageShown) {
        message.error("Document does not exist.");
        setErrorMessageShown(true);

        // Automatically reset error message state after 5 seconds (5000 milliseconds)
        setTimeout(() => {
          setErrorMessageShown(false);
        }, 5000);
      }
    }
  };

  const customRequest = ({ file, onSuccess, onError }) => {
    setFileUploading(true);
    // Custom logic to handle file upload
    const formData = new FormData();
    formData.append("agreement_file", file);
    formData.append("agreement_id", contract && contract.id);

    try {
      axios
        .post(API_URL + `/contract/save_agreement_file/`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          onSuccess(); // Trigger success callback
          message.success("Document Saved Successfully.");
          fetchContracts();
          setFileUploading(false);
        })
        .catch((error) => {
          onError(); // Trigger error callback
          message.error(error.response.data.error);
          setFileUploading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const uploadFile = async ({ id, clause }) => {
    setMasterTempUploading(true);
    const formData = new FormData();
    formData.append("agreement_id", contract && contract.id);

    // Custom logic to handle different parameters
    if (id) {
      formData.append("master_template_id", id);
    }
    if (clause) {
      formData.append("clause_list", JSON.stringify(clause));
    }

    try {
      if (id) {
        setIsMasterLoading(true);
      }
      if (clause) {
        setIsClauseLoading(true);
      }
      const response = await axios.post(
        API_URL + `/contract/save_agreement_file/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        message.success("Document Saved Successfully.");
        setMasterTempUploading(false);
        setIsMasterLoading(false);
        setIsClauseLoading(false);
        setClauseTempModal(false);
        setMasterTempModal(false);
      } else {
        setMasterTempUploading(false);
        setMasterTempModal(false);
        setIsMasterLoading(false);
        setClauseTempModal(false);
        setIsClauseLoading(false);
      }

      return { data: response.data, res: response };
    } catch (error) {
      console.log(error);
      setMasterTempUploading(false);
      setIsLoading(false);
      setMasterTempUploading(false);
      setMasterTempModal(false);
      setClauseTempModal(false);
      setIsMasterLoading(false);
      setIsClauseLoading(false);
    }
  };
  const uploadMasterTemp = ({ id }) => {
    uploadFile({ id });
  };
  const handleClauseSubmit = (clause) => {
    uploadFile({ clause: clause });
    // console.log(clause)
  };

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(API_URL + `/contract/master-template/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "multipart/form-data",
        },
      });
      setTemplateData(response.data);
    } catch (error) {}
  };
  const fetchMasterTemplates = async () => {
    try {
      const response = await axios.get(
        API_URL + `/contract/get-master-template/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      setMasterTemplateData(response.data);
    } catch (error) {}
  };
  console.log(templateData);
  console.log(masterTemplateData);

  const handleMasterTempButton = () => {
    setMasterTempModal(true);
  };
  const handleClauseTempButton = () => {
    setClauseTempModal(true);
  };

  const handleMasterTempCancelButton = () => {
    setMasterTempModal(false);
  };
  const handleClauseTempCancelButton = () => {
    setClauseTempModal(false);
  };

  const uploadContent = (
    <div>
      <div className="flex justify-center">
        <Upload
          customRequest={customRequest}
          showUploadList={false} // Hide the default file list
        >
          <Button
            icon={<UploadOutlined />}
            loading={fileUploading}
            className="menuButtton"
          >
            {fileUploading ? "Uploading" : "Upload from device"}
          </Button>
        </Upload>
      </div>
      <div>
        <Button
          icon={<MdUploadFile />}
          loading={isMasterLoading}
          className="menuButtton"
          onClick={() => handleMasterTempButton()}
        >
          {"Master Template"}
        </Button>
      </div>
      <div>
        <Button
          icon={<RiGalleryUploadLine />}
          loading={isClauseLoading}
          className="menuButtton"
          onClick={() => handleClauseTempButton()}
        >
          {"Clause"}
        </Button>
      </div>
    </div>
  );

  const openInNewTab = (params, agreementFile) => {
    if (!params || !agreementFile) {
      if (!errorMessageShown) {
        message.error("Document does not exist.");
        setErrorMessageShown(true);

        // Automatically reset error message state after 5 seconds (5000 milliseconds)
        setTimeout(() => {
          setErrorMessageShown(false);
        }, 5000);
      }
    } else {
      const agreementFileEncoded = encodeURIComponent(
        JSON.stringify(agreementFile)
      );

      window.open(
        `/edit-document/${params}?agreementFile=${agreementFileEncoded}`,
        "_blank"
      );
    }
  };

  const dropdownStyle = {
    width: "auto", // Allow the dropdown to adjust its width based on content
    minWidth: "150px", // Set a minimum width if needed
    maxWidth: "300px", // Set a maximum width if needed
  };
  const handleSendButton = () => {
    setIsSendModalVisible(true);
  };
  const handleThirdPartyButton = () => {
    setIsThirdPartyVisible(true);
  };
  const handleDiffUserButton = () => {
    setIsDifferentUser(true);
  };
  const handleSendSubmit = (values) => {
    const updatedValues = {
      ...values,
      agreement_id: contract && contract.id,
    };
    axios
      .post(API_URL + `/contract/send_agreement/`, updatedValues, config)
      .then((response) => {
        message.success(response.data.message);
        setIsSendModalVisible(false);
        // fetchContracts();
        form.resetFields();
        navigate("/admin/contracts/");
      })
      .catch((error) => {
        message.error(error.response.data.error);
        setIsSendModalVisible(false);
        form.resetFields();
      });
  };
  const handleProductSubmit = () => {
    const newProductList = [...productList, ...data];
    handleSave({ product_list: newProductList });
  };
  const assignDiffUser = (values) => {
    const updatedValues = {
      ...values,
      agreement_id: contract && contract.id,
    };
    axios
      .put(
        API_URL + `/contract/assign-agreement-to-diff-user/`,
        updatedValues,
        config
      )
      .then((response) => {
        message.success(response.data.message);
        setIsDifferentUser(false);
        fetchContracts();
        form.resetFields();
      })
      .catch((error) => {
        message.error("Some Error Occured.");
        setIsDifferentUser(false);
        form.resetFields();
      });
  };
  const handleThirdPartySubmit = (values) => {
    const updatedValues = {
      ...values,
      agreement_id: contract && contract.id,
    };
    axios
      .post(
        API_URL + `/contract/send-agreement-to-third-party/`,
        updatedValues,
        config
      )
      .then((response) => {
        message.success(response.data.message);
        setIsThirdPartyVisible(false);
        fetchContracts();
        form.resetFields();
        navigate("/admin/contracts/");
      })
      .catch((error) => {
        message.error(error.response.data.error);
        setIsSendModalVisible(false);
        form.resetFields();
      });
  };

  const handleSubmitSendToSelf = () => {
    const updatedValues = {
      receiver: [`${localStorage.getItem("user_email")}`],
      agreement_id: contract && contract.id,
      remark: "Sent to self",
    };
    axios
      .post(
        API_URL + `/contract/send-agreement-to-third-party/`,
        updatedValues,
        config
      )
      .then((response) => {
        message.success(response.data.message);
        setIsThirdPartyVisible(false);
        fetchContracts();
        form.resetFields();
        navigate("/admin/contracts/");
      })
      .catch((error) => {
        message.error(error.response.data.error);
        setIsSendModalVisible(false);
        form.resetFields();
      });
  };

  const fetchCompanyUsers = async () => {
    try {
      const response = await axios.get(API_URL + "/user/get_user_email/", {
        ...config,
        params: {
          ...config.params,
          is_active: true,
        },
      });
      const newOptions = response.data.data.map((item) => ({
        value: item.email, // Set the value to the email property
        label: item.first_name, // Set the label to a relevant property from your API data
      }));
      setCompanyUsers(newOptions);
      const newOptionsWithID = response.data.data.map((item) => ({
        value: item.id,
        label: item.first_name,
      }));
      setCompanyDiffUsers(newOptionsWithID);
    } catch (error) {}
  };
  useEffect(() => {
    fetchCompanyUsers();
  }, []);

  const fetchAgreementPdf = async (params) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        API_URL + "/contract/get_agreement_summary/",
        {
          ...config,
          params: {
            ...config.params,
            agreement_id: params,
          },
        }
      );
      if (response) {
        setIsLoading(false);
        // setAgreeementPdf(response.data.data);
        handlePdfDownload(response.data.data && response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  let contractUsersOption = [];
  if (contract && contract.contract_access_users) {
    const userEmail = localStorage.getItem("user_email");
    contractUsersOption = contract.contract_access_users.reduce(
      (options, user) => {
        if (user.email !== userEmail) {
          options.push({
            value: user.id,
            label: `${user.first_name}  (${user.role})`,
          });
        }
        return options;
      },
      []
    );
  }

  const handleEsignButton = async () => {
    setIsLoading(true);
    if (contract.agreement_file) {
      try {
        const response = await axios.post(
          API_URL + "/esign/add_document/",
          {
            agreement_id: contract.id,
            status: "UNDER_EXECUTION",
            remarks: "Send to Esign",
            agreement_file_edit_permission_user_id: contract.editable_user,
          },
          config
        );
        if (response?.data) {
          window.open(response.data.redirect_url, "_blank", "noreferrer");
          navigate("/admin/contracts");
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response);
      }
    }
  };

  const handleModifyDocument = () => {
    if (contract.esign_status) {
      setModifyOpen(true);
      return;
    }
    openInNewTab(contract && contract.id, contract && contract.agreement_file);
  };

  const handleEmailChange = (value) => {
    // Handle the changes in email addresses
    console.log(value);
  };
  const content = (
    <>
      <div className="flex flex-col">
        {role &&
        contract &&
        contract.editable_user &&
        parseInt(localStorage.getItem("user_id")) ===
          parseInt(contract.editable_user) ? (
          <>
            <Button
              className="menuButtton"
              onClick={() => handleSendButton()}
              icon={<BiSend />}
            >
              Send within organization
            </Button>
          </>
        ) : null}
        {role &&
        contract &&
        contract.editable_user &&
        parseInt(localStorage.getItem("user_id")) ===
          parseInt(contract.editable_user) ? (
          <>
            <Button
              className="menuButtton"
              onClick={() => handleThirdPartyButton()}
              icon={<FiSend />}
            >
              Send to Counter Party
            </Button>
          </>
        ) : null}
        {role &&
        contract &&
        contract.editable_user &&
        parseInt(localStorage.getItem("user_id")) ===
          parseInt(contract.editable_user) ? (
          <>
            <Button
              className="menuButtton"
              onClick={() => handleSubmitSendToSelf()}
              icon={<FiSend />}
            >
              Send to Self
            </Button>
          </>
        ) : null}
        {role && contract?.agreement_file ? (
          <>
            <Button
              className="menuButtton"
              onClick={() => handleEsignButton()}
              icon={<FaFileSignature />}
            >
              E-sign & E-stamp
            </Button>
          </>
        ) : null}
        {role &&
        contract &&
        contract.editable_user &&
        parseInt(localStorage.getItem("user_id")) ===
          parseInt(contract.editable_user) ? (
          <>
            <Button
              className="menuButtton"
              onClick={() => handleDiffUserButton()}
              icon={<MdAssignmentInd />}
            >
              Assign to Other User
            </Button>
          </>
        ) : null}
        <Button
          className="menuButtton"
          onClick={() => handleViewClick(contract.agreement_file)}
          icon={<FaDownload />}
        >
          Download Document
        </Button>
        {contract &&
        (contract.status === "EXECUTED" ||
          contract.status === "TRANSACTION_CLOSED") ? (
          ""
        ) : (
          <>
            {contract &&
            parseInt(localStorage.getItem("user_id")) ===
              parseInt(contract.editable_user) ? (
              <Button
                className="menuButtton"
                onClick={handleModifyDocument}
                icon={<LuClipboardEdit />}
              >
                Modify Document
              </Button>
            ) : (
              ""
            )}
          </>
        )}

        {(role &&
          contract &&
          role === "admin" &&
          contract.status === "EXECUTED" &&
          contract.is_created_from_bulk_upload &&
          !contract.is_agreement_file_uploaded) ||
        (role &&
          contract &&
          contract.editable_user &&
          contract.status !== "EXECUTED" &&
          contract.status !== "TRANSACTION_CLOSED" &&
          parseInt(localStorage.getItem("user_id")) ===
            parseInt(contract.editable_user)) ? (
          <>
            {role && (role === "legalhead" || role === "legalteam") ? (
              <>
                <Popover
                  content={uploadContent}
                  title=""
                  trigger="hover"
                  placement="bottom"
                >
                  <Button>Upload</Button>
                </Popover>
              </>
            ) : (
              <div className="flex justify-center">
                <Upload
                  customRequest={customRequest}
                  showUploadList={false} // Hide the default file list
                >
                  <Button
                    icon={<UploadOutlined />}
                    loading={fileUploading}
                    className="menuButtton"
                  >
                    {fileUploading ? "Uploading" : "Upload Document"}
                  </Button>
                </Upload>
              </div>
            )}
          </>
        ) : null}
        <div className="flex justify-center"></div>
      </div>
    </>
  );

  const pathname =
    referrer === "dashboard"
      ? "/admin/dashboard"
      : referrer === "audit-trail"
      ? "/admin/dashboard/audit-trail"
      : "/admin/contracts";

  const fetchProduct = async () => {
    try {
      const response = await axios.get(
        API_URL + "/company/get_product/",
        config
      );
      setProducts(response.data.data); // Update the state with the fetched user data
    } catch (error) {}
  };
  useEffect(() => {
    fetchProduct();
    fetchMasterTemplates();
    fetchTemplates();
  }, []);

  const productOptions =
    products &&
    Array.isArray(products) &&
    products
      .filter((item) => {
        // Assuming contract.product_list is an array of product objects
        return (
          contract &&
          !contract.product_list.some(
            (contractProduct) =>
              contractProduct.product_name === item.product_name
          )
        );
      })
      .map((item, index) => ({
        value: item.product_name,
        label: toTitleCase(item.product_name),
      }));

  useEffect(() => {
    const newRows =
      selectedProducts &&
      selectedProducts.map((product, index) => {
        const existingRow = data.find(
          (row) => row.product_name === product.label
        );
        return {
          product_name: product.value,
          invoice_frequency: existingRow ? existingRow.invoice_frequency : null,
          credit_term: existingRow ? existingRow.credit_term : null,
          invoice_raising_date: existingRow
            ? existingRow.invoice_raising_date
            : null,
          value: existingRow ? existingRow.value : null,
          custom_fields: existingRow ? existingRow.custom_fields : null,
        };
      });

    // setData((prevData) => [...prevData, ...newRows]);
    setData(newRows);
  }, [selectedProducts]);
  const addProductHandle = (values) => {
    setSelectedProducts(values);
  };
  let canShowErrorMessage = true; // Flag to control showing the error message

  const handleNext = () => {
    if (editMode || productEditMode) {
      if (canShowErrorMessage) {
        message.error("Please save/cancel form first.");
        canShowErrorMessage = false; // Disable showing the message temporarily
        setTimeout(() => {
          canShowErrorMessage = true; // Enable showing the message after some time
        }, 2000); // Adjust the time interval as needed (5000 milliseconds = 5 seconds)
      }
      setActiveTab(activeTab);
    } else {
      form.validateFields().then(() => {
        const nextTab = (parseInt(activeTab, 10) % finalPage) + 1;
        setActiveTab(nextTab.toString());
      });
    }
  };

  const handlePrevious = () => {
    if (editMode || productEditMode) {
      if (canShowErrorMessage) {
        message.error("Please save/cancel form first.");
        canShowErrorMessage = false; // Disable showing the message temporarily
        setTimeout(() => {
          canShowErrorMessage = true; // Enable showing the message after some time
        }, 2000); // Adjust the time interval as needed (5000 milliseconds = 5 seconds)
      }
      setActiveTab(activeTab);
    } else {
      const previousTab =
        ((parseInt(activeTab, 10) - 2 + finalPage) % finalPage) + 1;
      setActiveTab(previousTab.toString());
    }
  };
  const handleTabChange = (key) => {
    if (editMode || productEditMode) {
      if (canShowErrorMessage) {
        message.error("Please save/cancel form first.");
        canShowErrorMessage = false; // Disable showing the message temporarily
        setTimeout(() => {
          canShowErrorMessage = true; // Enable showing the message after some time
        }, 2000); // Adjust the time interval as needed (5000 milliseconds = 5 seconds)
      }
      setActiveTab(activeTab);
    } else {
      setActiveTab(key);
    }
  };

  const getAttachments = async () => {
    try {
      const response = await axios.get(API_URL + "/contract/attachment/", {
        ...config,
        params: {
          ...config.params,
          agreement_id: params.pk,
        },
      });
      if (response) {
        setAttachments(response.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAttachments(contract && contract.id);
  }, [contract]);

  const attchmentsColumns = [
    {
      title: "Sender",
      dataIndex: "sender_name",
    },
    {
      title: "Stage",
      dataIndex: "agreement_stage",
      render: (text) => capitalizeAndRemoveUnderscores(text),
    },
    {
      title: "Brief particulatrs",
      dataIndex: "brief_particulars",
    },
    {
      title: "Download Document",
      dataIndex: "attachment",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handlePdfDownload(record.attachment)}
          >
            <FaDownload />
          </Button>
        </Space>
      ),
    },
  ];

  const handleAttachemntClick = () => {
    setAttachmentModal(true);
  };

  const handleAddForm = () => {
    if (formFields.length < 5) {
      const newForm = { id: formFields.length + 1, value: "" };
      setFormFields([...formFields, newForm]);
    } else {
      // Optionally, you can provide feedback to the user that they can't add more forms
      message.error("You can only add upto 5 fields.");
    }
  };

  const handleRemoveForm = (id) => {
    if (formFields.length > 1) {
      const updatedFormFields = formFields.filter((field) => field.id !== id);
      setFormFields(updatedFormFields);
    }
  };
  const handleAttachmentSave = async () => {
    setUploading(true);
    await form.validateFields();
    const values = await form.getFieldsValue();
    // You can perform further actions with the saved attachment here
    const payload = {
      ...values,
      agreement_id: contract && contract.id,
      attachment: fileName && fileName,
    };
    axios
      .post(API_URL + `/contract/attachment/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        message.success("Document Saved Successfully.");
        setFormFields([{ id: 1, value: "" }]);
        fetchContracts();
        getAttachments(contract && contract.id);
        setAttachmentModal(false);
        setUploading(false);
        form.resetFields();
        setFilename();
      })
      .catch((error) => {
        message.error(error.response.data.error);
        setFormFields([{ id: 1, value: "" }]);
        setAttachmentModal(false);
        setUploading(false);
        form.resetFields();
        setFilename();
      });
  };

  const handleCustomFieldSave = async () => {
    await form.validateFields();
    const values = await form.getFieldsValue();
    console.log(values);
    let payload = {};
    payload = { ...contract.custom_field, ...values };
    handleSave({ custom_field: payload });
  };

  let customDFieldsCount = 0;
  if (contract && contract.custom_field) {
    customDFieldsCount = contract && Object.keys(contract.custom_field).length; // Get the number of keys in custom_field
  }
  const productsCount = contract && contract.product_list?.length; // Get the number of keys in custom_field

  const fetchClauses = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(API_URL + `/contract/clause/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "multipart/form-data",
        },
      });
      setIsLoading(false);
      setClausesData(response.data);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const fetchMasterClause = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        API_URL + `/contract/get-master-clause/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      setIsLoading(false);
      setMasterClausesData(response.data);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSequenceChange = (id, sequence) => {
    setClausesData(
      clausesData.map((item) => {
        if (item.id === id) {
          return { ...item, sequence };
        }
        return item;
      })
    );
  };

  const handleEsignStatus = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        API_URL + `/esign/get_document_status/`,
        { agreement_id: contract.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (response && response?.data) {
        const tempResponse = response?.data;
        const recipients = () => {
          const tempArr = tempResponse.recepients.map((recipient) => {
            return {
              fullName: recipient.FullName,
              email: recipient.EmailAddress,
              signatureImage: recipient.SignatureUpload,
              status: recipient.StatusCode,
            };
          });
          return tempArr;
        };

        setSignuluResponse({
          recepients: recipients(),
          addComments: tempResponse.addComments,
          senderName: tempResponse.senderName,
          statusCode: tempResponse.statusCode,
          expirationDate: tempResponse.expirationDate,
          fileName: tempResponse.uploadDocuments[0]?.FileName,
        });
        setEsignDetailModalOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      message.error(error.response.data.message);
    }
  };

  const handleEsignDocDownload = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        API_URL + `/esign/download_document/`,
        { agreement_id: contract.id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (response && response.data) {
        const blob = response.data?.document_url;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = blob;
        link.setAttribute("download", signuluResponse.fileName);
        handlePdfDownload(url);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download

        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setIsLoading(false);
        message.success("File downloaded successfully.");
      }
    } catch (error) {
      setIsLoading(false);
      message.error(error.response.data.error);
    }
  };

  useEffect(() => {
    if (clauseTempModal) {
      fetchMasterClause();
      fetchClauses();
    }
  }, [clauseTempModal]);

  return (
    <>
      <Spin spinning={isLoading} tip="Loading...">
        {contract ? (
          <>
            <div className="flex flex-row justify-between items-center h-16">
              <Link to={{ pathname }}>
                <IoMdArrowRoundBack size={32} />
              </Link>
              <div className="ml-2">
                {!editMode && (
                  <Popover
                    placement="left"
                    title={""}
                    content={content}
                    // trigger="click"
                    className="h-[3rem]"
                  >
                    <Button>
                      <CiMenuKebab />
                    </Button>
                  </Popover>
                )}
                <Modal
                  title="Send Contract"
                  open={isSendModalVisible}
                  onOk={handleOk}
                  onCancel={handleModalCancel}
                  style={{ fontSize: "24px", padding: "40px" }}
                  okText="Send"
                  okButtonProps={{
                    style: {
                      backgroundColor: "#4E36A3",
                      color: "white",
                      height: "40px",
                      width: "100px",
                    },
                  }} // Set background color for the Ok button
                  cancelButtonProps={{
                    style: {
                      backgroundColor: "",
                      color: "#4E36A3",
                      height: "40px",
                      width: "100px",
                    },
                  }} // Set background color for the Cancel button
                >
                  <Form form={form} onFinish={handleSendSubmit}>
                    <h1 className="font-lg">Please select the status</h1>
                    <Form.Item name="status" className="pt-1">
                      <Select
                        options={statusOptions}
                        onChange={selectedStatusOption}
                      ></Select>
                    </Form.Item>

                    {selectedStatus &&
                    (selectedStatus === "EXECUTED" ||
                      selectedStatus === "TRANSACTION_CLOSED") ? (
                      ""
                    ) : (
                      <>
                        <h1 className="font-lg">Edit right</h1>
                        <Form.Item
                          name="agreement_file_edit_permission_user_id"
                          className="pt-1"
                        >
                          <Select
                            options={contractUsersOption && contractUsersOption}
                          ></Select>
                        </Form.Item>
                      </>
                    )}

                    <h1 className="font-lg">Remark</h1>
                    <Form.Item
                      name="remarks"
                      className="pt-1"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a value.",
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                  </Form>
                </Modal>
                <Modal
                  title="Send Contract to Counter Party"
                  open={isThirdPartyVisible}
                  onOk={handleOk}
                  onCancel={handleModalCancel}
                  style={{ fontSize: "24px", padding: "40px" }}
                  okText="Send"
                  okButtonProps={{
                    style: {
                      backgroundColor: "#4E36A3",
                      color: "white",
                      height: "40px",
                      width: "100px",
                    },
                  }} // Set background color for the Ok button
                  cancelButtonProps={{
                    style: {
                      backgroundColor: "",
                      color: "#4E36A3",
                      height: "40px",
                      width: "100px",
                    },
                  }} // Set background color for the Cancel button
                >
                  <Form form={form} onFinish={handleThirdPartySubmit}>
                    <h1 className="font-lg">Receiver's Email</h1>
                    <Form.Item name="receiver" className="pt-1">
                      <Select
                        mode="tags"
                        style={{ width: "100%" }}
                        placeholder="Enter receiver's email"
                        onChange={handleEmailChange}
                        required
                      ></Select>
                    </Form.Item>
                    <h1 className="font-lg">Remark</h1>

                    <Form.Item name="remark" className="pt-1">
                      <TextArea required />
                    </Form.Item>
                  </Form>
                </Modal>
                <Modal
                  title="Are you sure?"
                  open={isModifyOpen}
                  onOk={() => {
                    openInNewTab(
                      contract && contract.id,
                      contract.id && contract.agreement_file_before_esign
                    );
                    setModifyOpen(false);
                  }}
                  onCancel={() => setModifyOpen(false)}
                  style={{ fontSize: "24px", padding: "40px" }}
                  okText="Yes"
                  okButtonProps={{
                    style: {
                      backgroundColor: "#4E36A3",
                      color: "white",
                      height: "40px",
                      width: "100px",
                    },
                  }} // Set background color for the Ok button
                  cancelButtonProps={{
                    style: {
                      backgroundColor: "",
                      color: "#4E36A3",
                      height: "40px",
                      width: "100px",
                    },
                  }} // Set background color for the Cancel button
                >
                  <h1>
                    Are you sure you want to modify document as it is sent for
                    esign?
                  </h1>
                </Modal>
                <Modal
                  title="Assign Contract to Different User"
                  open={isDifferentUser}
                  onOk={handleOk}
                  onCancel={handleModalCancel}
                  style={{ fontSize: "24px", padding: "40px" }}
                  okText="Send"
                  okButtonProps={{
                    style: {
                      backgroundColor: "#4E36A3",
                      color: "white",
                      height: "40px",
                      width: "100px",
                    },
                  }} // Set background color for the Ok button
                  cancelButtonProps={{
                    style: {
                      backgroundColor: "",
                      color: "#4E36A3",
                      height: "40px",
                      width: "100px",
                    },
                  }} // Set background color for the Cancel button
                >
                  <Form form={form} onFinish={assignDiffUser}>
                    <h1 className="font-lg">Please select user</h1>
                    <Form.Item name="user_id" className="pt-1">
                      <Select
                        options={companyDiffUsers.sort((a, b) =>
                          a.label.localeCompare(b.label)
                        )}
                      ></Select>
                    </Form.Item>
                  </Form>
                </Modal>
                <MasterTemplateModal
                  visible={masterTempModal}
                  onCancel={handleMasterTempCancelButton}
                  onSelect={uploadMasterTemp}
                  data={templateData}
                  masterTempData={masterTemplateData}
                  okText="Upload"
                  loading={masterTempUploading}
                />
                <ClausesModal
                  visible={clauseTempModal}
                  onCancel={handleClauseTempCancelButton}
                  clausesData={clausesData}
                  masterClausesData={masterClausesData}
                  handleSequenceChange={handleSequenceChange}
                  onSubmit={handleClauseSubmit}
                  // directSubmit={true}
                  loading={isLoading}
                />
              </div>
            </div>
            <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              type="card"
              style={{ flex: 1 }}
            >
              <TabPane tab="Business Detail" key="1">
                <div className="text-black pb-5 px-2 h-[55px] flex flex-col md:flex-row md:items-center md:justify-between">
                  <span className="flex flex-row justify-center text-center items-center">
                    <h1 className="mr-4 text-3xl font-bold font-inter">
                      Business Partner
                    </h1>
                    {role &&
                    contract &&
                    ((contract.editable_user &&
                      contract.status !== "EXECUTED" &&
                      contract.status !== "TRANSACTION_CLOSED" &&
                      parseInt(localStorage.getItem("user_id")) ===
                        parseInt(contract.editable_user) &&
                      ((role && role === "legalhead") ||
                        (role && role === "legalteam"))) ||
                      (contract.is_draft &&
                        contract.created_by ===
                          localStorage.getItem("user_email"))) ? (
                      <>
                        {!editMode && editedContract && (
                          <div className="edit-icon">
                            <BiEditAlt
                              onClick={handleEdit}
                              className="h-4 ml-2 text-lg transform scale-125"
                            />
                          </div>
                        )}
                      </>
                    ) : null}
                  </span>
                  {editMode && editedContract ? (
                    <div className="flex justify-between">
                      <div className="flex">
                        <ButtonComp
                          width="7em"
                          text={"Cancel"}
                          onClick={handleCancel}
                          margin={"4px"}
                          textColor="#4E36A3"
                        ></ButtonComp>
                        <ButtonComp
                          width="7em"
                          type="primary"
                          onClick={() => handleSave(newData)}
                          className=""
                          text={"Save"}
                          textColor="white"
                          bgColor="#4E36A3"
                        ></ButtonComp>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <Descriptions
                  title=""
                  layout="vertical"
                  className="responsive-descriptions"
                >
                  <Descriptions.Item label="Name">
                    {contract.name_of_client}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Authorized Signatory’s Name of Business Partner"
                    span={2}
                  >
                    {editMode && editedContract ? (
                      <Input
                        name="name_of_authorised_signatory_of_party"
                        value={
                          editedContract.name_of_authorised_signatory_of_party
                        }
                        onChange={handleInputChange}
                      />
                    ) : (
                      contract.name_of_authorised_signatory_of_party
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label="Address Line 1" span={1}>
                    {editMode && editedContract ? (
                      <Input
                        name="address1"
                        defaultValue={
                          editedContract &&
                          editedContract.client_address &&
                          editedContract.client_address.address1
                        }
                        // onChange={handleAdress1Change}
                        onChange={(e) =>
                          handleAddressChange(e, "client_address")
                        }
                        required
                      />
                    ) : (
                      contract &&
                      contract.client_address &&
                      contract.client_address.address1
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address Line 2" span={1}>
                    {editMode && editedContract ? (
                      <Input
                        name="address2"
                        defaultValue={
                          editedContract &&
                          editedContract.client_address &&
                          editedContract.client_address.address2
                        }
                        // onChange={handleAddress2Change}
                        onChange={(e) =>
                          handleAddressChange(e, "client_address")
                        }
                      />
                    ) : (
                      contract.client_address &&
                      contract.client_address.address2
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="City">
                    {editMode && editedContract ? (
                      <Input
                        name="city"
                        defaultValue={
                          editedContract &&
                          editedContract.client_address &&
                          editedContract.client_address.city
                        }
                        // onChange={handleCityChange}
                        onChange={(e) =>
                          handleAddressChange(e, "client_address")
                        }
                      />
                    ) : (
                      contract &&
                      contract.client_address &&
                      contract.client_address.city
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="PIN">
                    {editMode && editedContract ? (
                      <Input
                        name="postal_code"
                        defaultValue={
                          editedContract &&
                          editedContract.client_address &&
                          editedContract.client_address.postal_code
                        }
                        // onChange={handleAddressChange}
                        onChange={(e) =>
                          handleAddressChange(e, "client_address")
                        }
                      />
                    ) : (
                      contract &&
                      contract.client_address &&
                      contract.client_address.postal_code
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label="Country">
                    {editMode && editedContract ? (
                      <Select
                        defaultValue={
                          contract &&
                          contract.client_address &&
                          contract.client_address.country &&
                          contract.client_address.country.id
                        }
                        // onChange={handleClientCountryChange}
                        onChange={(value) =>
                          handleAddressChange(
                            { target: { name: "country", value } },
                            "client_address",
                            "select",
                            "country"
                          )
                        }
                        style={{ width: "100%" }} // Set the width of the Select component
                        dropdownStyle={dropdownStyle} //
                        autoWidth
                      >
                        {countries &&
                          countries.map((country) => (
                            <Select.Option key={country.id} value={country.id}>
                              {country.name}
                            </Select.Option>
                          ))}
                      </Select>
                    ) : (
                      contract &&
                      contract.client_address &&
                      contract.client_address.country &&
                      contract.client_address.country.name
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="State">
                    {editMode && editedContract ? (
                      <Select
                        defaultValue={
                          contract &&
                          contract.client_address &&
                          contract.client_address.state &&
                          contract.client_address.state.id
                        }
                        // onChange={handleClientStateChange}
                        // onChange={(e) => handleAddressChange(e, "client_address")}

                        onChange={(value) =>
                          handleAddressChange(
                            { target: { name: "state", value } },
                            "client_address",
                            "select",
                            "state"
                          )
                        }
                        autoWidth
                        style={{ width: "100%" }} // Set the width of the Select component
                      >
                        {clientStates &&
                          clientStates.map((state) => (
                            <Select.Option key={state.id} value={state.id}>
                              {state.name}
                            </Select.Option>
                          ))}
                      </Select>
                    ) : (
                      contract &&
                      contract.client_address &&
                      contract.client_address.state &&
                      contract.client_address.state.name
                    )}
                  </Descriptions.Item>
                </Descriptions>
                <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                  Business Entity
                </h1>
                <Descriptions
                  title=""
                  layout="vertical"
                  className="mt-4 responsive-descriptions"
                >
                  <Descriptions.Item label="Name">
                    {contract.party}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Authorized Signatory’s Name of Business Entity"
                    span={2}
                  >
                    {editMode && editedContract ? (
                      <Input
                        name="name_of_authorised_signatory_of_client"
                        value={
                          editedContract.name_of_authorised_signatory_of_client
                        }
                        onChange={handleInputChange}
                      />
                    ) : (
                      contract.name_of_authorised_signatory_of_client
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address Line 1" span={1}>
                    {editMode && editedContract ? (
                      <Input
                        name="address1"
                        defaultValue={
                          editedContract &&
                          editedContract.party_address &&
                          editedContract.party_address.address1
                        }
                        // onChange={handleAdressChange}
                        // onChange={handlePinChange}
                        onChange={(e) =>
                          handleAddressChange(e, "party_address")
                        }
                      />
                    ) : (
                      contract &&
                      contract.party_address &&
                      contract.party_address.address1
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address Line 2" span={1}>
                    {editMode && editedContract ? (
                      <Input
                        name="address2"
                        defaultValue={
                          editedContract &&
                          editedContract.party_address &&
                          editedContract.party_address.address2
                        }
                        // onChange={handleAddress2Change}
                        onChange={(e) =>
                          handleAddressChange(e, "party_address")
                        }
                      />
                    ) : (
                      contract.party_address && contract.party_address.address2
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="City" span={1}>
                    {editMode && editedContract ? (
                      <Input
                        name="city"
                        defaultValue={
                          editedContract &&
                          editedContract.party_address &&
                          editedContract.party_address.city
                        }
                        // onChange={handlPartyCityChange}
                        onChange={(e) =>
                          handleAddressChange(e, "party_address")
                        }
                      />
                    ) : (
                      contract &&
                      contract.party_address &&
                      contract.party_address.city
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="PIN" span={1}>
                    {editMode && editedContract ? (
                      <Input
                        name="postal_code"
                        defaultValue={
                          editedContract &&
                          editedContract.party_address &&
                          editedContract.party_address.postal_code
                        }
                        // onChange={handleAddressChange}
                        onChange={(e) =>
                          handleAddressChange(e, "party_address")
                        }
                      />
                    ) : (
                      contract &&
                      contract.party_address &&
                      contract.party_address.postal_code
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label="Country" span={1}>
                    {editMode && editedContract ? (
                      <Select
                        defaultValue={
                          contract &&
                          contract.party_address &&
                          contract.party_address.country &&
                          contract.party_address.country.id
                        }
                        // onChange={handlePartyCountryChange}
                        onChange={(value) =>
                          handleAddressChange(
                            { target: { name: "country", value } },
                            "party_address",
                            "select",
                            "country"
                          )
                        }
                        autoWidth
                        style={{ width: "100%" }}
                      >
                        {countries &&
                          countries.map((country) => (
                            <Select.Option key={country.id} value={country.id}>
                              {country.name}
                            </Select.Option>
                          ))}
                      </Select>
                    ) : (
                      contract &&
                      contract.party_address &&
                      contract.party_address.country &&
                      contract.party_address.country.name
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="State" span={1}>
                    {editMode && editedContract ? (
                      <Select
                        defaultValue={
                          contract &&
                          contract.party_address &&
                          contract.party_address.state &&
                          contract.party_address.state.id
                        }
                        // onChange={handlePartyStateChange}
                        onChange={(value) =>
                          handleAddressChange(
                            { target: { name: "state", value } },
                            "party_address",
                            "select",
                            "state"
                          )
                        }
                        autoWidth
                        style={{ width: "100%" }}
                      >
                        {partyStates &&
                          partyStates.map((state) => (
                            <Select.Option key={state.id} value={state.id}>
                              {state.name}
                            </Select.Option>
                          ))}
                      </Select>
                    ) : (
                      contract &&
                      contract.party_address &&
                      contract.party_address.state &&
                      contract.party_address.state.name
                    )}
                  </Descriptions.Item>
                </Descriptions>
                {contract && (
                  <Tooltip title={"Download Ageement Details"}>
                    <FloatButton
                      onClick={() => fetchAgreementPdf(contract.id)}
                      icon={isLoading ? <ImSpinner3 /> : <FaDownload />}
                      description=""
                      shape="square"
                      style={{
                        right: 120,
                        width: "6em",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    />
                  </Tooltip>
                )}
              </TabPane>
              <TabPane tab="Agreement Detail" key="2">
                <div className="text-black pb-5 px-2 h-[55px] flex flex-col md:flex-row md:items-center md:justify-between">
                  <div className="agreement-details">
                    <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                      Agreement Details
                    </h1>

                    {role &&
                    contract &&
                    ((contract.editable_user &&
                      contract.status !== "EXECUTED" &&
                      contract.status !== "TRANSACTION_CLOSED" &&
                      parseInt(localStorage.getItem("user_id")) ===
                        parseInt(contract.editable_user) &&
                      ((role && role === "legalhead") ||
                        (role && role === "legalteam"))) ||
                      (contract.is_draft &&
                        contract.created_by ===
                          localStorage.getItem("user_email"))) ? (
                      <>
                        {!editMode && editedContract && (
                          <div className="edit-icon">
                            <BiEditAlt
                              onClick={handleEdit}
                              className="h-4 ml-2 text-lg transform scale-125"
                            />
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                  {/* <div className="text-black pb-3 px-2 h-[55px] flex flex-col md:flex-row md:items-center md:justify-between overflow-x-auto"> */}
                  <div className="text-black pb-3 px-2 h-[55px] flex flex-col md:flex-row md:items-center md:justify-between">
                    {editMode && editedContract ? (
                      <div className="flex justify-between">
                        <div className="flex">
                          <ButtonComp
                            width="7em"
                            text={"Cancel"}
                            onClick={handleCancel}
                            margin={"4px"}
                            textColor="#4E36A3"
                          ></ButtonComp>
                          <ButtonComp
                            width="7em"
                            type="primary"
                            onClick={() => handleSave(newData)}
                            className=""
                            text={"Save"}
                            textColor="white"
                            bgColor="#4E36A3"
                          ></ButtonComp>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <Descriptions
                  title=""
                  layout="vertical"
                  bordered
                  className="agreement-detail"
                >
                  <Descriptions.Item label="Agreement Type" span={1}>
                    {editMode && editedContract ? (
                      <Select
                        defaultValue={contract && contract.agreement_type}
                        onChange={handleAgreementChange}
                        name="agreement_type"
                        autoWidth
                        // style={{ width: "100%" }}
                        optionLabelProp="label" // Use option's label as the display text
                        className="custom-dropdown w-full" // Add a custom class for styling
                      >
                        {agreementOptions &&
                          agreementOptions.map((agreement) => (
                            <Select.Option
                              key={agreement.value}
                              value={agreement.value}
                              defaultValue={contract.agreement_type}
                            >
                              {agreement.label}
                            </Select.Option>
                          ))}
                      </Select>
                    ) : (
                      contract && contract.agreement_type
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label="Nature">
                    {editMode && editedContract ? (
                      <Select
                        defaultValue={contract && contract.nature}
                        onChange={handleNatureChange}
                        name="nature"
                        autoWidth
                        style={{ width: "100%" }}
                      >
                        {natureOptions &&
                          natureOptions.map((nature) => (
                            <Select.Option
                              key={nature.value}
                              value={nature.value}
                              defaultValue={contract.nature}
                            >
                              {nature.label}
                            </Select.Option>
                          ))}
                      </Select>
                    ) : (
                      contract && contract.nature
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Tenure">
                    {editMode && editedContract ? (
                      <Input
                        name="tenure"
                        value={editedContract.tenure}
                        onChange={handleInputChange}
                      />
                    ) : (
                      contract.tenure
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Termination Notice">
                    {editMode && editedContract ? (
                      <Input
                        name="termination_notice"
                        value={editedContract.termination_notice}
                        onChange={handleInputChange}
                      />
                    ) : (
                      contract.termination_notice
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Jurisdiction">
                    {editMode && editedContract ? (
                      <Input
                        name="jurisdiction"
                        value={editedContract.jurisdiction}
                        onChange={handleInputChange}
                      />
                    ) : (
                      contract.jurisdiction
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Execution Date" span={1}>
                    {editMode && editedContract ? (
                      <DatePicker
                        name="date_of_execution"
                        style={{ width: "100%" }}
                        format="DD-MM-YYYY"
                        defaultValue={
                          contract.date_of_execution
                            ? dayjs(
                                moment(
                                  contract.date_of_execution,
                                  "YYYY-MM-DD"
                                ).format("DD-MM-YYYY"),
                                dateFormat
                              )
                            : null
                        }
                        onChange={(date) =>
                          setExecutionDate(date?.format("YYYY-MM-DDTHH:mm:ss"))
                        }
                        disabledDate={(current) =>
                          current && current > moment().endOf("day")
                        } // Disable dates after today
                      />
                    ) : (
                      contract.date_of_execution &&
                      moment(contract.date_of_execution).format("DD-MM-YYYY")
                    )}
                  </Descriptions.Item>

                  <Descriptions.Item label="Effective Date" span={1}>
                    {editMode && editedContract ? (
                      <DatePicker
                        name="effective_date"
                        style={{ width: "100%" }}
                        format="DD-MM-YYYY"
                        defaultValue={
                          contract.effective_date
                            ? dayjs(
                                moment(
                                  contract.effective_date,
                                  "YYYY-MM-DD"
                                ).format("DD-MM-YYYY"),
                                dateFormat
                              )
                            : null
                        }
                        onChange={(date) =>
                          setEffectiveDate(date?.format("YYYY-MM-DDTHH:mm:ss"))
                        }
                      />
                    ) : (
                      contract.effective_date &&
                      moment(contract.effective_date).format("DD-MM-YYYY")
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Remarks" span={2}>
                    <div className="flex justify-between">
                      <div>{contract.remarks}</div>
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label="Status">
                    {capitalizeAndRemoveUnderscores(contract.status)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Agreeement ID">
                    {contract.document_id && contract.document_id}
                  </Descriptions.Item>
                </Descriptions>
              </TabPane>
              <TabPane tab="Products / Services" key="3">
                <div className="text-black pb-5 px-2 h-[55px] flex flex-col md:flex-row md:items-center md:justify-between">
                  <div className="agreement-details">
                    <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                      Products / Services
                    </h1>

                    {productsCount !== 0 &&
                    role &&
                    contract &&
                    ((contract.editable_user &&
                      contract.status !== "EXECUTED" &&
                      contract.status !== "TRANSACTION_CLOSED" &&
                      parseInt(localStorage.getItem("user_id")) ===
                        parseInt(contract.editable_user) &&
                      ((role && role === "legalhead") ||
                        (role && role === "legalteam"))) ||
                      (contract.is_draft &&
                        contract.created_by ===
                          localStorage.getItem("user_email"))) ? (
                      <>
                        {!productEditMode && editedContract && (
                          <div className="edit-icon">
                            <BiEditAlt
                              onClick={handleProductEdit}
                              className="h-4 ml-2 text-lg transform scale-125"
                            />
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                  {/* <div className="text-black pb-3 px-2 h-[55px] flex flex-col md:flex-row md:items-center md:justify-between overflow-x-auto"> */}
                  <div className="text-black pb-3 px-2 h-[55px] flex flex-col md:flex-row md:items-center md:justify-between">
                    {productEditMode ? (
                      <div className="flex justify-between">
                        <div className="flex">
                          <ButtonComp
                            width="7em"
                            text={"Cancel"}
                            onClick={handleCancel}
                            margin={"4px"}
                            textColor="#4E36A3"
                          ></ButtonComp>
                          <ButtonComp
                            width="7em"
                            type="primary"
                            onClick={() =>
                              handleSave({ product_list: productList })
                            }
                            className=""
                            text={"Save"}
                            textColor="white"
                            bgColor="#4E36A3"
                          ></ButtonComp>
                        </div>
                      </div>
                    ) : (
                      <>
                        {role &&
                        contract &&
                        ((contract.editable_by &&
                          role === contract.editable_by.toLowerCase() &&
                          ((role && role === "legalhead") ||
                            (role && role === "legalteam"))) ||
                          (contract.is_draft &&
                            contract.created_by ===
                              localStorage.getItem("user_email"))) ? (
                          <>
                            <Button
                              type="primary"
                              onClick={() => setProductModal(true)}
                            >
                              <AddOutlined /> Add More
                            </Button>
                          </>
                        ) : null}

                        <Modal
                          title="Add Products / Services"
                          open={productModal}
                          onOk={handleOk}
                          className="product-table-update"
                          onCancel={handleModalCancel}
                          style={{ fontSize: "24px", padding: "40px" }}
                          okText="Save"
                          okButtonProps={{
                            style: {
                              backgroundColor: "#4E36A3",
                              color: "white",
                              height: "40px",
                              width: "100px",
                            },
                          }} // Set background color for the Ok button
                          cancelButtonProps={{
                            style: {
                              backgroundColor: "",
                              color: "#4E36A3",
                              height: "40px",
                              width: "100px",
                            },
                          }} // Set background color for the Cancel button
                        >
                          <Form form={form} onFinish={handleProductSubmit}>
                            <Form.Item label="" name="product_list">
                              <CreatableSelect
                                isMulti
                                options={productOptions}
                                required
                                onChange={addProductHandle}
                              />
                            </Form.Item>
                          </Form>
                          {selectedProducts && selectedProducts.length > 0 ? ( // Render the Table only when there are selected products
                            <div className="product-table">
                              <Table
                                dataSource={data}
                                columns={productColumn}
                                pagination={false}
                                bordered
                                className="mb-4 product-table"
                                scroll={{ x: "max-content" }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </Modal>
                      </>
                    )}
                  </div>
                </div>
                <Table
                  dataSource={contract && contract.product_list}
                  columns={productColumns}
                  pagination={false}
                  bordered
                  className="mb-4"
                  scroll={{ x: "max-content" }}
                />
              </TabPane>
              <TabPane tab="Version Control" key="4">
                <div className=" text-black pb-3 px-2 h-[60px] flex items-center justify-between">
                  <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                    Version Control
                  </h1>
                  {contract.esign_status !== "draft" && (
                    <Button
                      onClick={handleEsignStatus}
                      type="submit"
                      className="flex items-center rounded-full border-2 border-success px-6 pb-[6px] pt-2 bg-green-500 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-success-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-success-600 focus:border-success-600 focus:text-black focus:outline-none focus:ring-0 active:border-success-700 active:text-black-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10 my-0"
                    >
                      View E-sign Status
                    </Button>
                  )}
                </div>
                <Modal
                  title="E-sign Status"
                  centered
                  open={esignDetailModalOpen}
                  closable={false}
                  maskClosable
                  width={1000}
                  footer={
                    <Button
                      type="default"
                      onClick={() => setEsignDetailModalOpen(false)}
                    >
                      Close
                    </Button>
                  }
                >
                  <>
                    <div className="text-sm font-semibold my-2">
                      Signature Initiated By-
                      <span className="font-medium mx-2">
                        {signuluResponse.senderName}
                      </span>
                    </div>
                    <div className="text-sm font-semibold my-2">
                      Comments
                      <span className="font-medium mx-2">
                        {signuluResponse.addComments
                          ? signuluResponse?.addComments.map(
                              (comment, index) => {
                                return (
                                  <span>
                                    {index !== signuluResponse.length() - 1
                                      ? `${comment},`
                                      : `${comment}`}
                                  </span>
                                );
                              }
                            )
                          : "No Comments"}
                      </span>
                    </div>
                    <div className="text-sm font-semibold my-2">
                      Expiration Date-
                      <span className="font-medium mx-2">
                        {signuluResponse?.expirationDate}
                      </span>
                    </div>
                    <div className="text-sm font-semibold my-2">
                      E-sign Status-
                      <span className="font-semibold mx-2">
                        {signuluResponse?.statusCode?.toLowerCase() ===
                        "approved" ? (
                          <span className="bg-[#479D491A] text-[#479D49] px-2 py-2">
                            {signuluResponse.statusCode}
                          </span>
                        ) : signuluResponse?.statusCode?.toLowerCase() ===
                          "rejected" ? (
                          <span className="text-[#BA3928] bg-[#BA39281A] px-2 py-2">
                            {signuluResponse.statusCode}
                          </span>
                        ) : (
                          <span className="text-[#F7CB73] bg-[#F7CB731A] px-2 py-2 ">
                            {signuluResponse.statusCode}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="">
                      <div className="text-sm font-semibold flex justify-between items-center h-10">
                        <span>Recipients Details</span>
                        {signuluResponse?.statusCode?.toLowerCase() !==
                          "pending" && (
                          <Button
                            onClick={handleEsignDocDownload}
                            type="submit"
                            className="flex items-center rounded-full border-2 border-success px-6 pb-[6px] pt-2 bg-green-500 text-xs font-medium uppercase leading-normal text-black transition duration-150 ease-in-out hover:border-success-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-success-600 focus:border-success-600 focus:text-black focus:outline-none focus:ring-0 active:border-success-700 active:text-black-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10 my-0"
                          >
                            Download Signed Document
                          </Button>
                        )}
                      </div>
                      <Table
                        dataSource={signuluResponse?.recepients}
                        columns={esignColumns}
                        pagination={false}
                        scroll={{ x: "max-content" }}
                        bordered
                        className="mb-4"
                      />
                    </div>
                  </>
                </Modal>
                <Table
                  dataSource={contract && contract.contract_history}
                  columns={columns}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                  bordered
                  className="mb-4"
                />
              </TabPane>
              <TabPane tab="Custom Tab" key="5">
                <div className="text-black pb-5 px-2 h-[55px] flex flex-col md:flex-row md:items-center md:justify-between">
                  <div className="agreement-details">
                    <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                      Custom Tab
                    </h1>

                    {customDFieldsCount !== 0 &&
                    role &&
                    contract &&
                    ((contract.editable_user &&
                      contract.status !== "EXECUTED" &&
                      contract.status !== "TRANSACTION_CLOSED" &&
                      parseInt(localStorage.getItem("user_id")) ===
                        parseInt(contract.editable_user) &&
                      ((role && role === "legalhead") ||
                        (role && role === "legalteam"))) ||
                      (contract.is_draft &&
                        contract.created_by ===
                          localStorage.getItem("user_email"))) ? (
                      <>
                        {!productEditMode && editedContract && (
                          <div className="edit-icon">
                            <BiEditAlt
                              onClick={handleProductEdit}
                              className="h-4 ml-2 text-lg transform scale-125"
                            />
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="text-black pb-3 px-2 h-[55px] flex flex-col md:flex-row md:items-center md:justify-between">
                    {productEditMode ? (
                      <div className="flex justify-between">
                        <div className="flex">
                          <ButtonComp
                            width="7em"
                            text={"Cancel"}
                            onClick={handleCancel}
                            margin={"4px"}
                            textColor="#4E36A3"
                          ></ButtonComp>
                          <ButtonComp
                            width="7em"
                            type="primary"
                            onClick={() =>
                              handleSave({ custom_field: updatedCustomForm })
                            }
                            className=""
                            text={"Save"}
                            textColor="white"
                            bgColor="#4E36A3"
                          ></ButtonComp>
                        </div>
                      </div>
                    ) : (
                      <>
                        {role &&
                        contract &&
                        ((contract.editable_user &&
                          contract.status !== "EXECUTED" &&
                          contract.status !== "TRANSACTION_CLOSED" &&
                          parseInt(localStorage.getItem("user_id")) ===
                            parseInt(contract.editable_user) &&
                          ((role && role === "legalhead") ||
                            (role && role === "legalteam"))) ||
                          (contract.is_draft &&
                            contract.created_by ===
                              localStorage.getItem("user_email"))) ? (
                          <>
                            <Button
                              type="primary"
                              onClick={() => {
                                setCustomModal(true);
                              }}
                            >
                              <AddOutlined /> Add More
                            </Button>
                          </>
                        ) : null}

                        <Modal
                          title="Add Custom Fields"
                          open={customModal}
                          onOk={handleOk}
                          className="custom-table-update"
                          onCancel={handleModalCancel}
                          style={{ fontSize: "24px", padding: "40px" }}
                          okText="Save"
                          okButtonProps={{
                            style: {
                              backgroundColor: "#4E36A3",
                              color: "white",
                              height: "40px",
                              width: "100px",
                            },
                          }} // Set background color for the Ok button
                          cancelButtonProps={{
                            style: {
                              backgroundColor: "",
                              color: "#4E36A3",
                              height: "40px",
                              width: "100px",
                            },
                          }} // Set background color for the Cancel button
                        >
                          {customFields.map((field, index) => (
                            <Form form={form} onFinish={handleCustomFieldSave}>
                              <Row key={index} gutter={16}>
                                <Col xs={24} sm={24} md={8} lg={8}>
                                  <Form.Item
                                    label={`Field ${
                                      index +
                                      findMaxIndex(
                                        contract && contract.custom_field
                                      ) +
                                      1
                                    }`}
                                    name={`field${
                                      index +
                                      findMaxIndex(
                                        contract && contract.custom_field
                                      ) +
                                      1
                                    }`}
                                  >
                                    <Input
                                      value={field.field}
                                      onChange={(e) =>
                                        handleCustomFieldChange(
                                          index,
                                          e.target.value,
                                          field.value
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8}>
                                  <Form.Item
                                    label={`Value ${
                                      index +
                                      findMaxIndex(
                                        contract && contract.custom_field
                                      ) +
                                      1
                                    }`}
                                    name={`value${
                                      index +
                                      findMaxIndex(
                                        contract && contract.custom_field
                                      ) +
                                      1
                                    }`}
                                  >
                                    <Input
                                      value={field.value}
                                      onChange={(e) =>
                                        handleCustomFieldChange(
                                          index,
                                          field.field,
                                          e.target.value
                                        )
                                      }
                                      required
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8}>
                                  <Button
                                    type="danger"
                                    onClick={() =>
                                      handleRemoveCustomField(index)
                                    }
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          ))}
                          <Button
                            type="dashed"
                            onClick={handleAddCustomField}
                            className="mb-4"
                            block
                          >
                            <PlusCircleOutlined />
                            Add Custom Field
                          </Button>
                        </Modal>
                      </>
                    )}
                  </div>
                </div>

                <Table
                  dataSource={combinedCustomList}
                  columns={customTabColumns}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                  bordered
                  className="mb-4"
                />
              </TabPane>
              <TabPane tab="User Access" key="6">
                <div className=" text-black pb-3 px-2 h-[60px] flex items-center justify-between">
                  <span>
                    <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                      Contract Access Users
                    </h1>
                  </span>
                  <>
                    <>
                      {contract.status !== "EXECUTED" &&
                        contract.status !== "TRANSACTION_CLOSED" && (
                          <ButtonComp
                            text="+ Add Access User"
                            bgColor="white"
                            textColor="#4E36A3"
                            onClick={showUserAddModal}
                          ></ButtonComp>
                        )}

                      <Modal
                        title="Add User Email"
                        open={isUserAddModalVisible}
                        onOk={handleOk}
                        onCancel={handleModalCancel}
                        okText="Save"
                      >
                        <Form form={form} onFinish={handleAddUserSubmit}>
                          <Form.Item
                            name="user_email"
                            label="E-mail"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid E-mail!",
                              },
                              {
                                required: true,
                                message: "Please enter user E-mail!",
                              },
                            ]}
                          >
                            <Select
                              options={companyUsers.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )}
                              isMulti
                              required
                              closeMenuOnSelect={false}
                              onChange={handleReceiversEmailChange}
                              // menuPlacement="top"
                              // required
                            />
                          </Form.Item>
                        </Form>
                      </Modal>
                    </>
                  </>
                </div>
                <Row gutter={[16, 16]}>
                  {contract &&
                    contract.contract_access_users &&
                    contract.contract_access_users.map((user) => (
                      <>
                        <Col xs={24} sm={16} md={12} lg={8} xl={8}>
                          <Card
                            hoverable
                            title={`${user.first_name} ${
                              user.last_name ? user.last_name : ""
                            }`}
                            bordered={false}
                            extra={
                              <Popconfirm
                                title="Remove this user"
                                description="Are you sure to remove this user?"
                                onConfirm={() =>
                                  handleAddUserSubmit({
                                    user_email: user.email,
                                    assign: false,
                                  })
                                }
                                onCancel={handleCancel}
                                okText="Yes"
                                cancelText="No"
                              >
                                {role &&
                                contract &&
                                contract.is_draft &&
                                contract.created_by ===
                                  localStorage.getItem("user_email") ? (
                                  <>
                                    {!editMode &&
                                      editedContract &&
                                      user.email !==
                                        localStorage.getItem("user_email") && (
                                        <Button danger>Remove</Button>
                                      )}
                                  </>
                                ) : null}
                              </Popconfirm>
                            }
                            className="mb-3 ml-1 mr-1 user-card"
                          >
                            <div>
                              <div>
                                <span className="font-bold">Role : </span>{" "}
                                {user.role}
                              </div>
                              <div>
                                {/* <span className="font-bold">Email : </span> */}
                                <Text className="email">
                                  Email : {user.email}
                                </Text>
                              </div>
                              <div>
                                <span className="font-bold">Department : </span>
                                {user.department && user.department.name}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </>
                    ))}
                </Row>
              </TabPane>
              <TabPane tab="Other Attachments" key="7">
                <div className=" text-black pb-3 px-2 h-[60px] flex items-center justify-between">
                  <span className="flex flex-row justify-between align-middle text-center">
                    <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
                      Other Attachments
                    </h1>
                  </span>
                  <div>
                    {role &&
                    contract &&
                    ((contract.editable_user &&
                      contract.status !== "EXECUTED" &&
                      contract.status !== "TRANSACTION_CLOSED" &&
                      parseInt(localStorage.getItem("user_id")) ===
                        parseInt(contract.editable_user)) ||
                      (contract.is_draft &&
                        contract.created_by ===
                          localStorage.getItem("user_email"))) ? (
                      <>
                        <ButtonComp
                          text="Add Attachments"
                          bgColor="#4E36A3"
                          textColor="white"
                          onClick={handleAttachemntClick}
                        />
                      </>
                    ) : null}
                  </div>

                  <Modal
                    title="Add Attachments"
                    open={attachmentModal}
                    onOk={handleOk}
                    className="attachment-modal"
                    onCancel={handleModalCancel}
                    style={{ fontSize: "24px", padding: "40px" }}
                    footer
                  >
                    <div className="p-4">
                      <Row className="mb-2">
                        <Col span={8}>Brief Particulars</Col>
                        <Col span={8}>Attachment</Col>
                        <Col span={8}></Col>
                      </Row>
                      <Row>
                        <Form form={form} onFinish={handleAddUserSubmit}>
                          {formFields.map((field, index) => (
                            <div
                              key={field.id}
                              className="flex items-center text-center mb-4 justify-center"
                            >
                              <Col span={8}>
                                <Form.Item
                                  name="brief_particulars"
                                  className="flex flex-row attachment-input"
                                >
                                  <Input required />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name="attachment"
                                  valuePropName="fileList"
                                  getValueFromEvent={(e) => e.fileList}
                                  className="mr-2 attachment-input"
                                >
                                  <Input
                                    type="file"
                                    onChange={(e) =>
                                      setFilename(e.target.files[0])
                                    }
                                    className="form-control"
                                    accept=".doc, .docx"
                                    required
                                  ></Input>
                                </Form.Item>
                              </Col>

                              <Col span={8}>
                                <Button
                                  type="primary"
                                  onClick={() => handleAttachmentSave(index)}
                                  className="mr-2"
                                  loading={uploading}
                                  disabled={!fileName}
                                >
                                  Save
                                </Button>
                                {/* <Button
					  type="primary"
					  onClick={handleAddForm}
					  className="mr-2"
					  disabled={!fileName}

					>
					  +
					</Button>
					<Button
					  type="primary"
					  onClick={() => handleRemoveForm(field.id)}
					  danger
					  disabled={!fileName}

					>
					  -
					</Button> */}
                              </Col>
                            </div>
                          ))}
                        </Form>
                      </Row>
                    </div>
                  </Modal>
                </div>
                <Table
                  dataSource={attachments}
                  columns={attchmentsColumns}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                  bordered
                  className="mb-4"
                />
              </TabPane>
              {/* <TabPane tab="Discussions" key="8">
		  <div className=" text-black pb-3 px-2 h-[60px] flex items-center justify-between">
		    <span className="flex flex-row justify-between align-middle text-center">
			<h1 className="mt-1 mr-4 text-3xl font-bold font-inter">
			  Other Attachments
			</h1>
		    </span>
		    <div>
			{role &&
			contract &&
			((contract.editable_user &&
			  contract.status !== "EXECUTED" &&
			  contract.status !== "TRANSACTION_CLOSED" &&
			  parseInt(localStorage.getItem("user_id")) ===
			    parseInt(contract.editable_user) &&
			  ((role && role === "legalhead") ||
			    (role && role === "legalteam"))) ||
			  (contract.is_draft &&
			    contract.created_by ===
				localStorage.getItem("user_email"))) ? (
			  <>
			    <ButtonComp
				text="Add Attachments"
				bgColor="#4E36A3"
				textColor="white"
				onClick={handleAttachemntClick}
			    />
			  </>
			) : null}
		    </div>

		    <Modal
			title="Add Attachments"
			open={attachmentModal}
			onOk={handleOk}
			className="attachment-modal"
			onCancel={handleModalCancel}
			style={{ fontSize: "24px", padding: "40px" }}
			footer
		    >
			<div className="p-4">
			  <Row className="mb-2">
			    <Col span={8}>Brief Particulars</Col>
			    <Col span={8}>Attachment</Col>
			    <Col span={8}></Col>
			  </Row>
			  <Row>
			    <Form form={form} onFinish={handleAddUserSubmit}>
				{formFields.map((field) => (
				  <div
				    key={field.id}
				    className="flex items-center text-center mb-4 justify-center"
				  >
				    <Col span={8}>
					<Form.Item
					  name="brief_particulars"
					  className="flex flex-row attachment-input"
					>
					  <Input required />
					</Form.Item>
				    </Col>
				    <Col span={8}>
					<Form.Item
					  name="attachment"
					  valuePropName="fileList"
					  getValueFromEvent={(e) => e.fileList}
					  className="mr-2 attachment-input"
					>
					  <Input
					    type="file"
					    onChange={(e) =>
						setFilename(e.target.files[0])
					    }
					    className="form-control"
					    required
					  ></Input>
					</Form.Item>
				    </Col>

				    <Col span={8}>
					<Button
					  type="primary"
					  onClick={handleAttachmentSave}
					  className="mr-2"
					  loading={uploading}
					>
					  Save
					</Button>
					<Button
					  type="primary"
					  onClick={handleAddForm}
					  className="mr-2"
					>
					  +
					</Button>
					<Button
					  type="primary"
					  onClick={() => handleRemoveForm(field.id)}
					  danger
					>
					  -
					</Button>
				    </Col>
				  </div>
				))}
			    </Form>
			  </Row>
			</div>
		    </Modal>
		  </div>
		  <Table
		    dataSource={attachments}
		    columns={attchmentsColumns}
		    pagination={false}
		    scroll={{ x: "max-content" }}
		    bordered
		    className="mb-4"
		  />
		</TabPane> */}
            </Tabs>
            <div
              style={{
                position: "relative",
                bottom: 0,
                left: 0,
                // width: "105.1%",

                display: "flex",
                justifyContent: "flex-start",
                // backgroundColor: "#EBE8F7",
                paddingTop: "30px",
              }}
            >
              {activeTab !== "1" && (
                <ButtonComp
                  text="Previous"
                  textColor="#4E36A3"
                  bgColor="white"
                  onClick={handlePrevious}
                  margin="2rem"
                />
              )}
              {parseInt(activeTab) !== parseInt(finalPage) ? (
                <>
                  {editMode ? (
                    ""
                  ) : (
                    <ButtonComp
                      text="Next"
                      bgColor="#4E36A3"
                      textColor="white"
                      onClick={handleNext}
                    />
                  )}
                </>
              ) : (
                <>
                  {editMode ? (
                    ""
                  ) : (
                    <ButtonComp
                      text="Finish"
                      bgColor="#4E36A3"
                      textColor="white"
                      onClick={handleNext}
                    />
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </Spin>
    </>
  );
}

export default ContractDetailComp;
