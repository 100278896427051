// RightSide.jsx
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, Row, Col, Select } from "antd";
import Logo from "../../assets/images/logo.jpeg";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../api/api";
import { extractUserInfoFromToken } from "../../utils/utils";
import { message } from "antd";
import { API_URL } from "../../utils/Constant";
import axios from "../../utils/axios";
import { signupCompany } from "../../api/api";
import SuccessModal from "../modal/SuccessModal";
import { LoadingOutlined } from "@ant-design/icons";
import { getAllISOCodes } from "iso-country-currency";
import { default as ReactSelect } from "react-select";

const { Option } = Select;

const SignupComponent = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isModal, setIsModal] = useState(true);
  const [countries, setCountries] = useState([]);
  const [countryCode, setCountryCode] = useState();
  const [country, setCountry] = useState(); // Add state for country
  const [states, setStates] = useState();
  const [allCurrency, setAllCurrency] = useState([]);

  const handleCountryChange = (e) => {
    const selectedCountryId = e;
    const selectedCountry = countries[selectedCountryId - 1];
    const countryCode = selectedCountry ? selectedCountry.country_code : "";
    setCountryCode(countryCode);
    setCountry(e);
    fetchState(selectedCountryId);
  };

  const fetchState = async (value) => {
    try {
      const response = await axios.get(API_URL + "/state/get/", {
        params: {
          country_id: value,
        },
      });

      setStates(response.data); // Update the state with the fetched user data
    } catch (error) {}
  };
  const onFinish = async (values) => {
    console.log(values, "asdhjgaghjagsdah");
    const address = {
      country: parseInt(values.country),
    };
    try {
      setIsLoading(true);
      const { res } = await signupCompany({
        name: values.companyName,
        admin_email: values.email,
        admin_contact_number: values.phone,
        first_name: values.userName,
        address: address,
        currency: values.currency.value,
      });
      if (res.status === 201) {
        setIsLoading(false);
        setIsModal(true);
        message.success(
          "Company Created Successfully. A link has been sent to user's email to reset password."
        );
        // return (window.location.href = "/auth/login/");
        navigate("/auth/login");
      } else {
        message.error(res);
      }
    } catch (err) {
      message.error(err.error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(API_URL + "/country/get/");
        setCountries(response.data); // Update the state with the fetched user data
      } catch (error) {}
    };
    fetchCountry();
    const fetchData = async () => {
      try {
        const codes = await getAllISOCodes();
        const transformedData = codes.map((code) => ({
          value: `${code.countryName} (${code.symbol})`,
          label: `${code.countryName} (${code.symbol})`,
        }));
        setAllCurrency(transformedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCurrencyChange = (value) => {
    // form.setFieldsValue({ "address.country": value });
    // setCompanyID(value.value);
    // setEditedCompany((prevAddress) => ({
    //   ...prevAddress,
    //   currency: value.value,
    // }));
    // fetchState(value);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 18, // Set height to 56px 
      width: "100%", // Full width
      border: "1px solid #ccc", // Optional: Border styling
      borderRadius: "8px", // Optional: Smooth border
      boxShadow: state.isFocused ? "0 0 0 2px #2684FF" : "none", // Focus state styling
      "&:hover": {
        borderColor: "#2684FF", // Hover effect on border
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "grey", // Arrow icon color
      padding: "0 8px", // Adjust padding for alignment
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide the separator line
    }),
  };

  return (
    <>
      {isModal ? (
        <SuccessModal
          modal="true"
          message="User created successfully. A link has been sent to your email to reset your password."
          link="/auth/login"
        />
      ) : (
        ""
      )}
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: "80%", width: 400 }}>
          <div
            style={{
              textAlign: "left",
              marginBottom: 20,
              fontSize: "40px",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1>
              <span className="text-indigo-600">C</span>ore
              <span className="text-indigo-600">V</span>entum
            </h1>
          </div>
          <h1
            style={{
              lineHeight: "38.73px",
              fontWeight: "700",
              fontSize: "30px",
              marginBottom: "2rem",
            }}
          >
            Sign Up
          </h1>
          <Form onFinish={onFinish}>
            <Col>User Name</Col>
            <Form.Item name="userName" label="" style={{ marginBottom: 20 }}>
              <Input placeholder="Enter your username" required />
            </Form.Item>
            <Col>Company Name</Col>
            <Form.Item name="companyName" label="" style={{ marginBottom: 20 }}>
              <Input placeholder="Enter your company name" required />
            </Form.Item>
            <Col>Email Address</Col>
            <Form.Item name="email" label="" style={{ marginBottom: 20 }}>
              <Input
                type="email"
                placeholder="Enter your email address"
                required
              />
            </Form.Item>

            <Col>Country</Col>
            <Form.Item name="country" label="" style={{ marginBottom: 20 }}>
              <Select
                showSearch
                placeholder="Select your country"
                style={{ width: "100%" }}
                onChange={handleCountryChange}
                optionFilterProp="children" // specify which property of the Option will be used for the search
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {countries.map((country) => (
                  <Option key={country.id} value={country.id}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Col>Base Currency</Col>
            <Form.Item name="currency" label="" style={{ marginBottom: 20 }}>
              <ReactSelect
                options={allCurrency}
                styles={customStyles}
                onChange={handleCurrencyChange}
              />
            </Form.Item>
            {/* <Col>State</Col>
            <Form.Item name="state" label="" style={{ marginBottom: 20 }}>
              <Select
                placeholder="Select your state"
                style={{ width: "100%" }}
                onChange={handleCountryChange}
              >
                {states &&
                  states.map((state) => (
                    <Option key={state.id} value={state.id}>
                      {state.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item> */}

            <Col>Contact Number</Col>
            <Form.Item
              name="phone"
              label=""
              style={{ marginBottom: 20 }}
              rules={[
                {
                  required: true,
                  message: "Please input your contact number!",
                },
                {
                  pattern: /^\d{10}$/,
                  message: "Please input a 10-digit phone number!",
                },
              ]}
            >
              <Input
                addonBefore={countryCode ? countryCode : "+91"}
                placeholder="Enter your contact number"
              />
            </Form.Item>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: 24 }}
            >
              <Col>
                <Form.Item name="agree" valuePropName="checked" noStyle>
                  <Checkbox required></Checkbox>
                  <span className="ml-2">
                    By clicking here, I state that I have read and understood
                    the terms and conditions policy
                  </span>
                </Form.Item>
              </Col>
              {/* <Col><a href="#">Forgot password?</a></Col> */}
            </Row>
            <Form.Item>
              <Button
                type="primary"
                block
                htmlType="submit"
                style={{ backgroundColor: "#4E36A3", borderColor: "#4E36A3" }}
                disabled={isLoading ? true : false}
              >
                Sign Up
              </Button>
            </Form.Item>
            <div style={{ textAlign: "left" }}>
              <p>
                Already a user?{" "}
                <NavLink
                  to={"/auth/login/"}
                  style={{ color: "#4E36A3", fontWeight: "bold" }}
                >
                  Log In
                </NavLink>
              </p>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SignupComponent;
