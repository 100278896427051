import React from "react";
import { Card, Col } from "antd";
import { NavLink } from "react-router-dom";

function DashboardCard({ bgColor, icon, to, text, today, total }) {
  return (
    <>
      <Col xs={24} sm={24} md={4}>
        <NavLink to={to}>
          <Card
            title=""
            bordered={true}
            className={`w-[110%] ${bgColor} request-card`}
          >
            <div className="h-20 flex flex-col">
              <div className="flex flex-row h-16 justify-between items-center">
                <div>
                  <h1 className="text-2xl font-bold font-sans">{total}</h1>

                  <h3
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "14.52px",
                    }}
                  >
                    {text}
                  </h3>
                </div>

                <div>
                  <img
                    src={icon}
                    alt=""
                    className="max-w-16 max-h-16 float-right"
                  ></img>
                </div>
              </div>
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    fontSize: "12px",
                    lineHeight: "14.52px",
                  }}
                >
                  + {today} Unread
                </h2>
              </div>
            </div>
          </Card>
        </NavLink>
      </Col>
    </>
  );
}

export default DashboardCard;
