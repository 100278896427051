import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Button,
  Spin,
  Modal,
  Form,
  Input,
  Row,
  Col,
  message,
  Tooltip,
  Upload,
} from "antd";
import { API_URL } from "../../utils/Constant";
import axios from "../../utils/axios";
import { handlePdfDownload } from "../../utils/utils";
import { FaDownload } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { limitWords } from "../../utils/utils";
import { FaSave } from "react-icons/fa";
function ClauseTemplate() {
  const [form] = Form.useForm();
  const [clauseData, setClauseData] = useState([]);
  const [masterClauesData, setMasterClauesData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaveAsModal, setIsSaveAsModal] = useState(false);
  const [recordData, setRecordData] = useState(null); // State to hold record data

  const [fileName, setFilename] = useState();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const updateRecordData = (
    clauseName,
    description,
    serialNumber,
    implementationInstances,
    masterClauseid
  ) => {
    setRecordData({
      clause_name: clauseName,
      description: description,
      serial_number: serialNumber,
      implementation_instances: implementationInstances,
      master_clause_id: masterClauseid,
    });

    setIsSaveAsModal(true);
  };
  const clickSaveAs = () => {
    setIsSaveAsModal(true);
  };

  const handleOk = async () => {
    await form.validateFields();
    const values = await form.getFieldsValue();
    try {
      setIsFormLoading(true);

      const response = await axios.post(
        API_URL + `/contract/clause/`,
        { ...values, clause_file: fileName && fileName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        message.success(response.data.message);
        fetchClauses();
        setIsModalOpen(false);
        setIsFormLoading(false);
        form.resetFields();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsModalOpen(false);
      form.resetFields();
    }
  };
  const handleSaveAsOk = async () => {
    await form.validateFields();
    const values = await form.getFieldsValue();
    try {
      setIsFormLoading(true);

      const response = await axios.post(
        API_URL + `/contract/save-as-clause/`,
        { ...values, master_clause_id: recordData?.master_clause_id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        message.success(response.data.message);
        fetchClauses();
        fetchMasterClause();
        setIsSaveAsModal(false);
        form.resetFields();
      } else {
        message.error(response.data.message);
        setIsSaveAsModal(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsSaveAsModal(false);
      form.resetFields();
    }
  };

  const onCancel = () => {
    setIsModalOpen(false);
    setIsFormLoading(false);
    setIsSaveAsModal(false);
    setRecordData(null);
    form.resetFields();
  };

  const fetchClauses = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(API_URL + `/contract/clause/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-type": "multipart/form-data",
        },
      });
      console.log(response.data, "clauses");
      setIsLoading(false);
      setClauseData(response.data);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const fetchMasterClause = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        API_URL + `/contract/get-master-clause/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-type": "multipart/form-data",
          },
        }
      );
      console.log(response.data, "hjasfgdhgs");
      setIsLoading(false);
      setMasterClauesData(response.data);
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchClauses();
    fetchMasterClause();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(API_URL + `/contract/clause/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        params: {
          clause_id: id,
        },
      });
      if (response.status === 200) {
        message.success(response.data.message);
        fetchClauses();
        fetchMasterClause();
      } else {
        message.error(response.data.message);
      }
    } catch (err) {}
  };
  const clauseColumns = [
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Clause name",
      dataIndex: "clause_name",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Implementation Instances",
      dataIndex: "implementation_instances",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Download",
      dataIndex: "clause_file",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handlePdfDownload(record.clause_file)}
          >
            <FaDownload />
          </Button>
        </Space>
      ),
    },
    {
      title: "Delete",
      dataIndex: "template_file",
      render: (_, record) => (
        <Space>
          <Button type="" onClick={() => handleDelete(record.id)}>
            <MdDelete />
          </Button>
        </Space>
      ),
    },
  ];
  const masterClauseColumns = [
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Clause name",
      dataIndex: "clause_name",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Implementation Instances",
      dataIndex: "implementation_instances",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record) => (
        <Tooltip title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {limitWords(text, 6)}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Download",
      dataIndex: "clause_file",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => handlePdfDownload(record.clause_file)}
          >
            <FaDownload />
          </Button>
        </Space>
      ),
    },
    {
      title: "Save As",
      dataIndex: "id",
      render: (_, record) => (
        <Space>
          <Button
            type="primary"
            onClick={() => {
              console.log("records", record);
              updateRecordData(
                record.clause_name,
                record.description,
                record.serial_number,
                record.implementation_instances,
                record.id
              );
              // setRecordData({
              //   clause_name: record.clause_name,
              //   description: record.description,
              //   serial_number: record.serial_number,
              //   master_clause_id: record.id,
              // });
              clickSaveAs();
            }}
          >
            <FaSave />
          </Button>
        </Space>
      ),
    },
  ];
  console.log(clauseData)

  return (
    <>
      <div className=" text-black pb-3 px-2 h-[60px] flex items-center justify-between">
        <span>
          <h1 className="mt-1 mr-4 text-3xl font-bold font-inter">Clauses</h1>
        </span>
        <>
          <Button type="primary" onClick={showModal}>
            Add Clauses
          </Button>
          <Modal
            open={isModalOpen}
            title="Add Clause"
            onCancel={onCancel}
            footer={[
              <Button key="cancel" onClick={onCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={handleOk}
                loading={isFormLoading}
              >
                Submit
              </Button>,
            ]}
          >
            <Form form={form} layout="vertical">
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Clause Name"
                    name="clause_name"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Clause  Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Implementation Instances"
                    name="implementation_instances"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Implementation Instances" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Description" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Select File"
                    name="clause_file"
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                      return e.target.files;
                    }}
                    className="mr-2 attachment-input"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <Input
                      type="file"
                      accept=".doc, .docx"
                      onChange={(e) => setFilename(e.target.files[0])}
                      className="form-control"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
          <Modal
            title="Add Company"
            open={isSaveAsModal}
            onOk={handleSaveAsOk}
            onCancel={onCancel}
            style={{ fontSize: "24px", padding: "40px" }}
            okText="Save"
            okButtonProps={{
              style: {
                backgroundColor: "#4E36A3",
                color: "white",
                height: "40px",
                width: "100px",
              },
              disabled: isLoading, // Disable the button when loading is true
            }} // Set background color for the Ok button
            cancelButtonProps={{
              style: {
                backgroundColor: "",
                color: "#4E36A3",
                height: "40px",
                width: "100px",
              },
            }} // Set background color for the Cancel button
          >
            {/* <p>{modalText}</p> */}
            <Form form={form}>
              <Col>Clause Name</Col>
              <Form.Item
                label=""
                name="clause_name"
                initialValue={recordData && recordData.clause_name}
              >
                <Input
                  placeholder=""
                  defaultValue={recordData && recordData?.clause_name}
                />
              </Form.Item>
              <Col>Description</Col>

              <Form.Item
                label=""
                name="description"
                initialValue={recordData?.description}
              >
                <Input placeholder="" defaultValue={recordData?.description} />
              </Form.Item>
              <Col>Serial Number</Col>
              <Form.Item
                label=""
                name="serial_number"
                initialValue={recordData?.serial_number}
              >
                <Input
                  placeholder=""
                  defaultValue={recordData?.serial_number}
                />
              </Form.Item>
              <Col>Implementation Instances</Col>
              <Form.Item
                label=""
                name="implementation_instances"
                initialValue={recordData?.implementation_instances}
              >
                <Input
                  placeholder=""
                  defaultValue={recordData?.implementation_instances}
                />
              </Form.Item>
            </Form>
          </Modal>
        </>
      </div>
      <Spin spinning={isLoading} tip="Loading...">
        <Table
          dataSource={clauseData && clauseData}
          columns={clauseColumns}
          pagination={true}
          bordered
          className="mb-4 template-table"
          scroll={{ x: "max-content" }}
        />
      </Spin>
      {(localStorage.getItem("user_role") === "legalteam" ||
        localStorage.getItem("user_role") === "legalhead") && (
        <>
          <span>
            <h1 className="mt-1 mr-4 text-3xl font-bold font-inter mb-2">
              Master Clauses
            </h1>
          </span>
          <Spin spinning={isLoading} tip="Loading...">
            <Table
              dataSource={masterClauesData && masterClauesData}
              columns={masterClauseColumns}
              pagination={true}
              bordered
              className="mb-4 template-table"
              scroll={{ x: "max-content" }}
            />
          </Spin>
        </>
      )}
    </>
  );
}

export default ClauseTemplate;
